import {SET_ORDERS_LIST, SET_EDIT_ORDER} from '../types'

const initialProductState = {
    orders: [], 
    editOrder: null
  };

export default function orders(state = initialProductState, action){    
    switch(action.type){      
        case SET_ORDERS_LIST:
          return {...state, orders: action.orders}
        case SET_EDIT_ORDER:
          return {...state, editOrder: state.orders.filter(o => o._id === action.orderId)[0]}
        default:
          return state;
    }   
}