import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {ShoppingCart} from '@material-ui/icons'
import {IconButton, Badge} from '@material-ui/core'
import {IUser} from '../interfaces/User.interface'
import { connect, useDispatch } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {setBuyNow} from '../redux/action'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root:{
        position: 'fixed',
        top: 200,        
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        padding: '10px',
        backgroundColor: '#ccc',
        borderTop: 'solid 1px #2F519C',
        borderBottom: 'solid 1px #2F519C',
        borderLeft: 'solid 1px #2F519C',
        borderRadius: '50% 0px 0px 50%',
        opacity: 0.8,
    },
    '@media (max-width: 420px)': {
        root:{
            bottom: 0,
            top: 'unset',
            zIndex: 1,
            right: '24px',
            border: 'unset',
            backgroundColor: 'unset',
        },
    }    
  }))

export interface INavBarProps{
    user: IUser,
    countItems: number,
}

type Props = INavBarProps & RouteComponentProps

const ShopCartLink: React.FC<Props> = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()   
    const countItems: number = props.countItems

    const onClickHandler = () =>{
        dispatch(setBuyNow(false))
        props.history.push('/cart')
    }

    console.log('ShopCartLink count in cart: ', countItems)
    return(
        <div className={classes.root}>
            <IconButton color="inherit" onClick={()=> { onClickHandler() }}>
                <Badge badgeContent={countItems} color="secondary" >
                    <ShoppingCart />
                </Badge>
            </IconButton>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    console.log('state ShopCartLink: ', state)
    return {
      user: state.userState.user,
      countItems: state.shoppingCartState.countItems,
    }}

export default withRouter(connect(mapStateToProps)(ShopCartLink))

