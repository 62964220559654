import React, { useState, useEffect, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Button } from "@material-ui/core"
import { Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import NavBarCart from '../header/NavBarCart'
import {connect, useDispatch} from 'react-redux'
import {IShoppingCart, IOrderItem} from '../interfaces/ShoppingCart.interface'
import {IProduct} from '../interfaces/Product.interface'
import {IAlert} from '../interfaces/Alert.interface'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {setShoppingCart, saveOrder, showAlert} from '../redux/action'
import SnackbarsCustom from '../components/SnackbarsCustom'
import Footer from '../components/Footer'
import FooterMobile from '../components/FooterMobile'
import {RadioGroup , Radio, FormControlLabel } from '@material-ui/core'


const useStyles = makeStyles((theme: Theme) =>{
    return{
    table: {
        minWidth: 700,
    },
    tableHead: {
        backgroundColor: '#2F519C',    
    },
    tableHeadItem: {
        color: '#fff',
        fontSize: '17px',
    },
    tableItemImage: {
        width: 140,
    },
    tableItemDesc:{
        fontSize: '17px',
        paddingTop: '10px',
        marginRight: '15px',  
    },
    tableCellDesc: {
        width: '35%',
    },
    boxSubTitle:{
        display: 'flex',
        justifyContent: 'center'
    },
    boxSubTitleItem: {
        display: 'flex',
        marginLeft: '30px',
    },
    textFieldCount: {
        marginLeft: 8,
        marginRight: 8,
        width: 100, 
        height: '35px',
        borderTop: 'unset',
        borderRight: 'unset',
        borderLeft: 'unset',
        borderBottom: 'solid 1px #000',
        fontSize: '17px',
        textAlign: 'center',
        outline:0,          
    },
    tableCellCount: {
        width: '15%',
    },
    tableCellUnitPrice: {
        width: '15%',
    },
    tableCellSumPrice: {
        width: '15%',
    },
    totalContainer: {
        maxWidth: '440px',        
        marginTop: 20,
        marginBottom: 20,
        border: 'solid 1px #ccc',
        borderRadius: '5px',
        marginLeft: '0px',
        marginRight: 0,
    },
    boxBtnOrder:{
        backgroundColor: '#2F519C',
        color: '#fff',
        width: '140px',
        height: '40px',
        border: 'solid 1px #2F519C',
        borderRadius: '25px',
        fontSize: '12pt',
        fontWeight: 600,                 
        '&:hover': {
            backgroundColor: '#3b65c3',
        }
    },
    boxBtnOrderContainer:{
        textAlign: 'left',
    },
    textFieldShippingDetails: {
        marginLeft: 8,
        marginRight: 8,
        width: 300, 
        height: '35px',       
        fontSize: '17px',                        
    },
    shippingDetailsTitle: {
        color: '#2F519C',
        fontWeight: 700,
    },
    countBlock:{
        display: 'block'
    },
    countBlockMobile:{
        display: 'none',
        fontSize: '16px'
    },
    countMobileTitle:{
        paddingTop: '25px !important'
    },
    countMobileValue:{
        textAlign: 'right',
    },
    pr50:{
        paddingRight: '50px !important',
    },
    tblPaper:{
        marginTop: 80,
    },
    footerContainer:{
        height: '241px',
        position: 'absolute',        
        width: '100%',
        left: 0,
        right: 0,
    },
    divFooterMobile: {
        display: 'none',
    },
    orderUserPropsDiv: {
        height: 'calc(100% - 281px)',        
    },
    rgPayType: {
        flexDirection: 'row',
    },
    overflowXHiddenMobile:{
        
    },
    '@media (max-width: 420px)': {
        table:{
            display: 'block',
            minWidth: 'unset',
            '& thead': {
                display: 'none',
            },
            '& tbody': {
                display: 'block',
            },
            '& th': {
                display: 'block',
            },
            '& tr': {
                display: 'block',
            },
            '& td': {
                display: 'block',
                width: '100%',
            },
        },
        countBlock: {
            display: 'none',
        },
        countBlockMobile:{
            display: 'flex'
        },
        divFooterMobile: {
            display: 'block',
        },
        footerContainer:{
            display: 'none',
        },
        boxBtnOrderContainer:{
            textAlign: 'center',
        },
        overflowXHiddenMobile:{
            overflowX: 'hidden',
        },       
    },
  }
});

interface Row {
  desc: string;
  qty: number;
  unit: number;
  price: number;
}

interface IShoppingCartProps{
    shoppingCart:IShoppingCart
    editProduct:IProduct
    buyNow:boolean
    buyNowObj:IShoppingCart
    alert:IAlert
}

type Props = RouteComponentProps & IShoppingCartProps

const ShoppingCard: React.FC<Props> = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [countNew, setNewCount] = useState("")
    const [countNewId, setNewCountId] = useState("") 
    const [itemToRemove, setItemToRemove] = useState("")
    const [shipName, setShipName] = useState("")
    const [shipAddress, setShipAddress] = useState("")
    const [shipTelephon, setShipTelephon] = useState("")
    const [shipEmail, setShipEmail] = useState("")
    const [payType, setPayType] = useState("")
    const [payCardnNumber, setPayCardnNumber] = useState("")
    
    let shoppingCart:IShoppingCart
    let orderItems: IOrderItem[]
    
    if(props.buyNow){
        shoppingCart = props.buyNowObj
        orderItems = shoppingCart.order_items
    }else{
        shoppingCart = props.shoppingCart
        orderItems = shoppingCart.order_items 
    }

    console.log('shopping cart use state: ', useState)

    const subtotal = () => {
        return orderItems.map((item) => {return item.item_price_total}).reduce((sum, i) => sum + i, 0)       
    }   
    
    function ccyFormat(num: number){
        return `${num.toFixed(2)}`;
    }    
    
    const countChangeHandler = (e: React.ChangeEvent<{}>, id: string) =>{
        orderItems.map(item =>{
            if(item.item_sku === id){
                const element = e.currentTarget as HTMLInputElement
                item.quantity = element.value
                setNewCount(element.value)
                setNewCountId(id)
            }
            return item
        })
    }

    const getCountById = (id: string) => {
        if(id === countNewId){
            return countNew
        }else{
            return orderItems.filter(o => o.item_sku === id)[0].quantity
        }
    }

    const getCountTotalById = (id: string) => {
        let order = orderItems.filter(o => o.item_sku === id)[0]
        if(id === countNewId){
            order.item_price_total = order.item_price * parseInt(countNew)
            return parseInt(countNew) * order.item_price
        }else{            
            return order.item_price_total 
        }
    }

    const removeProduct = (id: string)=> {
        console.log(`remove input id: ${id}`)
        setItemToRemove(id)
        orderItems = orderItems.filter(o => o.item_sku !== id)
        shoppingCart.order_items = orderItems

        dispatch(setShoppingCart(shoppingCart))
        if(orderItems.length === 0){
            props.history.push('/')
        }
    }

    const getElementValue = (e: React.ChangeEvent<{}>) => {
        const el = e.target as HTMLInputElement
        return el.value
    }

    const payHandler = () => {
        let notValid = []
        
        if(shipName.trim() === ''){
            notValid.push('שם')
        }
        if(shipAddress.trim() === ''){
            notValid.push('כתובת')
        }
        if(shipTelephon.trim() === ''){
            notValid.push('טלפון')
        }

        if(notValid.length > 0){
            console.log(`not valud values: ${notValid}`)
            const alertData = {text: `not valud values: ${notValid}`, variant: 'error', show: true, event: '' };
            dispatch(showAlert(alertData))
            return
        }
        
        shoppingCart.shipping_address = {
            receiver_name: shipName,
            address: shipAddress,
            telephone: shipTelephon
        }
        shoppingCart.email = shipEmail
        shoppingCart.price = shoppingCart.shipping_cost + subtotal()
        shoppingCart.order_items = orderItems
        shoppingCart.quantity = orderItems.length
        shoppingCart.status = 'NEW'        
        dispatch(saveOrder(shoppingCart))
    }

    const handleChangePayType = (e: ChangeEvent<HTMLInputElement>) => {
        setPayType(e.target.value)
    }
  
    useEffect(()=>{
        window.scrollTo(0, 0)
    })

    const textFieldCardNumber = () => {        
        if(payType === '10bis' || payType === 'sodexo'){
            return <Grid item xs={12} sm={12} md={12} lg={12} className={''}>
                        <input type="text" placeholder="מס' כרטיס" className={classes.textFieldShippingDetails} value={payCardnNumber} 
                            onChange={(e: React.ChangeEvent<{}>) => {setPayCardnNumber(getElementValue(e))}}/> 
                    </Grid>
        }
    }
          
    return(
        <div className={classes.overflowXHiddenMobile +' container h-100pr'}>
        {props.alert.show ? 
            <SnackbarsCustom data={props.alert} />
            :''
        }
        <NavBarCart showGeneralBtn={false} showHomeBtn={true}/>
        <TableContainer component={Paper} className={classes.tblPaper}>
        <Table className={classes.table} aria-label="spanning table" >
          <TableHead className={classes.tableHead}>            
            <TableRow>
              <TableCell className={classes.tableHeadItem} align="right">שם חבילה</TableCell>
              <TableCell className={classes.tableHeadItem} align="center">כמות</TableCell>
              <TableCell className={classes.tableHeadItem} align="center">מחיר ליחידה</TableCell>
              <TableCell className={classes.tableHeadItem} align="center">סכום לתשלום</TableCell>
              <TableCell className={classes.tableHeadItem} align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderItems.map(row => (
              <TableRow key={row.item_sku}>
                <TableCell className={classes.tableCellDesc} align="right">
                    <div className="d-flex">
                        <img src={row.image.link} alt={row.image.title} className={classes.tableItemImage}/>
                        <div className={classes.tableItemDesc}>{row.title}</div> 
                    </div>
                </TableCell>
                <TableCell align="center" className={classes.tableCellCount}>
                    <Typography component="div" className={classes.boxSubTitle}>
                        <Typography component="div" className={classes.countBlock}>
                            <input type="number" min='1' max='100' className={classes.textFieldCount} value={getCountById(row.item_sku)} onChange={(e) => {countChangeHandler(e, row.item_sku)}}/>
                        </Typography>                       
                        <Grid container spacing={2} className={classes.countBlockMobile}>
                            <Grid item xs={4} className={classes.countMobileTitle}>
                                <span>כמות</span>
                            </Grid>
                            <Grid item xs={8} className={classes.countMobileValue}>
                                <input type="number" min='1' max='100' className={classes.textFieldCount} value={getCountById(row.item_sku)} onChange={(e) => {countChangeHandler(e, row.item_sku)}}/>
                            </Grid>                            
                        </Grid>
                    </Typography>
                </TableCell>
                <TableCell className={classes.tableCellUnitPrice}  align="center">
                    <Typography component="div" className={classes.countBlock}>
                        {row.item_price}
                    </Typography>
                    <Grid container spacing={2} className={classes.countBlockMobile}>
                        <Grid item xs={4} className={classes.countMobileTitle}>
                            <span>מחיר ליחידה</span>
                        </Grid>
                        <Grid item xs={8} className={classes.countMobileValue + ' ' + classes.countMobileTitle + ' ' + classes.pr50}>
                            {row.item_price}
                        </Grid>                            
                    </Grid>
                </TableCell>
                <TableCell className={classes.tableCellSumPrice} align="center">
                    <Typography component="div" className={classes.countBlock}>
                        {getCountTotalById(row.item_sku)}
                    </Typography>
                    <Grid container spacing={2} className={classes.countBlockMobile}>
                        <Grid item xs={4} className={classes.countMobileTitle}>
                            <span>סכום לתשלום</span>
                        </Grid>
                        <Grid item xs={8} className={classes.countMobileValue + ' ' + classes.countMobileTitle + ' ' + classes.pr50}>
                            {getCountTotalById(row.item_sku)}
                        </Grid>                            
                    </Grid>
                </TableCell>
                <TableCell className={classes.tableCellSumPrice} align="center">
                <IconButton aria-label="delete" onClick={() => {removeProduct(row.item_sku)}}>
                    <DeleteIcon />
                </IconButton>
                </TableCell>
              </TableRow>
            ))}            
          </TableBody>
        </Table>
      </TableContainer>
        <Grid container spacing={2} className={classes.orderUserPropsDiv}>
            <Grid item xs={12} sm={4} md={4} lg={4} className={''}>
                <Grid container spacing={2} className={classes.totalContainer + ' ptb-20'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={''}>
                        <span className={classes.shippingDetailsTitle}>פרטי תשלום</span> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={''}>
                        <RadioGroup aria-label="payType" name="rgPayType" value={payType} className={classes.rgPayType} onChange={handleChangePayType}>
                            <FormControlLabel value="creditCard" control={<Radio />} label="אשראי" />
                            <FormControlLabel value="cash" control={<Radio />} label="מזומן" />
                            <FormControlLabel value="10bis" control={<Radio />} label="10ביס" />
                            <FormControlLabel value="sodexo" control={<Radio />} label="סודקסו" />
                        </RadioGroup>
                    </Grid>
                    {textFieldCardNumber()}                                
                </Grid>
                <Grid className={classes.boxBtnOrderContainer}>
                    <Button className={classes.boxBtnOrder} onClick={payHandler}>לשלם</Button>
                </Grid>
            </Grid>      
            <Grid item xs={12} sm={4} md={4} lg={4} className={''}>
                <Grid container spacing={2} className={classes.totalContainer + ' ptb-20'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={''}>
                        <span className={classes.shippingDetailsTitle}>פרטי משלוח</span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={''}>
                        <input type="text" placeholder="שם מלא" className={classes.textFieldShippingDetails} onChange={(e: React.ChangeEvent<{}>) => {setShipName(getElementValue(e))}}/>   
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={''}>
                        <input type="text" placeholder="כתובת משלוח" className={classes.textFieldShippingDetails} onChange={(e: React.ChangeEvent<{}>) => {setShipAddress(getElementValue(e))}}/>                 
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={''}>
                        <input type="text" placeholder="טלפון" className={classes.textFieldShippingDetails} onChange={(e: React.ChangeEvent<{}>) => {setShipTelephon(getElementValue(e))}}/>                 
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={''}>
                        <input type="text" placeholder="אמייל" className={classes.textFieldShippingDetails} onChange={(e: React.ChangeEvent<{}>) => {setShipEmail(getElementValue(e))}}/>                 
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} className={''}>
            <Grid container spacing={2} className={classes.totalContainer} >
                    <Grid item xs={6} sm={5} md={5} lg={5} className={''}>
                        סכום
                    </Grid>
                    <Grid item xs={6} sm={5} md={5} lg={5} className={''}>
                        {ccyFormat(subtotal())}
                    </Grid>
                    <Grid item xs={6} sm={5} md={5} lg={5} className={''}>
                        משלוח
                    </Grid>
                    <Grid item xs={6} sm={5} md={5} lg={5} className={''}>
                        {shoppingCart.shipping_cost}
                    </Grid>
                    <Grid item xs={6} sm={5} md={5} lg={5} className={''}>
                    סכום לתשלום
                    </Grid>
                    <Grid item xs={6} sm={5} md={5} lg={5} className={''}>
                        {ccyFormat(shoppingCart.shipping_cost + subtotal())}
                    </Grid>
            </Grid>            
        </Grid>        
       </Grid>
       <div className={classes.footerContainer}>
        <Footer />
       </div>
       <div className={classes.divFooterMobile}>
            <FooterMobile />
        </div>
       </div>
    )
}

const mapStateToProps = (state: any) => {
 return {
     editProduct: state.productsState.editProduct,
     shoppingCart: state.shoppingCartState.shoppingCart,
     buyNow: state.productsState.buyNow,
     buyNowObj: state.productsState.buyNowObj,
     alert: state.alertState.alert,
 }   
}

export default withRouter(connect(mapStateToProps)(ShoppingCard))