import {SET_SHOPPING_CART, SET_ORDER_COMPLETE, FETCH_LIST_ORDERS} from '../types'

const getRndInteger = () => {
  return Math.floor(Math.random() * (10000000 - 1000)) + 1000;
}

const initialShoppingCartState = {
  shoppingCart: {
      sku: getRndInteger(),
      description: '',    
      price: 0,
      vat: 17,
      is_free_shipping: true,
      shipping_address: {},
      quantity: 1,
      status: "",
      shipping_date: "",
      user_id: "",     
      pay_transaction_id: "",
      pay_status: "",
      shipping_cost: 0,
      date_purchase: Date.now(),
      email:"", 
      order_items: []
  },
  savedOrder: {},
  listOrders: [],
  countItems: 0,  
};

export default function shoppingCart(state = initialShoppingCartState, action){
    switch(action.type){
        case SET_SHOPPING_CART:
          return { ...state, shoppingCart: action.shoppingCart, countItems: action.shoppingCart.order_items.length};
        case SET_ORDER_COMPLETE:
          return { ...state, savedOrder: action.savedOrder};
        case FETCH_LIST_ORDERS:
          return { ...state, listOrders: action.listOrders};
        default:
        return state;
    }
        
}