import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {PhoneInTalk} from '@material-ui/icons'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root:{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        padding: '10px',
        backgroundColor: '#ccc',
        borderTop: 'solid 2px #2F519C',
    },
    abutton:{
        color: '#039103',
        fontSize: '30px',
        backgroundColor: '#fff',
        borderRadius: '50%',
        display: 'block',
        width: '40px',
        height: '40px',
        border: 'solid 2px #039103',
    }
  }))

const FooterMobile: React.FC = () => {
    const classes = useStyles()
    return(
        <div className={classes.root}>
            <a href="tel:0587267676" className={classes.abutton}>
                <PhoneInTalk style={{marginTop: '8px'}}/>
            </a>
        </div>
    )
}

export default FooterMobile