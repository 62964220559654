import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles'
import { Theme, Link as MLink } from "@material-ui/core"
import NavBar from '../header/NavBarCart'
import ListBoxes from '../components/ListBoxes'
import SortBar from '../components/SortBar'
import Footer from '../components/Footer'
import FooterMobile from '../components/FooterMobile'

const useStyles = makeStyles((theme: Theme) => {
    return {
        divContainerProducts: {
            marginTop: 30
        },
        divSortBar: {
            marginTop: 100
        },
        divFooter:{
            display: 'block',
        },
        divFooterMobile: {
            display: 'none',
        },
        divListProducts:{
            marginBottom: 50
        },
        '@media (max-width: 420px)':{         
            divContainerProducts:{
                overflowX: 'hidden',                           
            },
            divSortBar: {
                marginTop: 50
            },
            divFooter:{
                display: 'none',
            },
            divFooterMobile: {
                display: 'block',
            },
            divListProducts:{
                marginBottom: 60
            },
        }
    }
})

const ListProducts: React.FC = () =>{
    const classes = useStyles()
    return(
        <div className={classes.divContainerProducts}>
            <NavBar showGeneralBtn={true} showHomeBtn={false}/>
            <div className={classes.divSortBar}>
                <SortBar />
            </div>
            <div className={classes.divListProducts}>
                <ListBoxes />
            </div>       
            <div className={classes.divFooter}>
                <Footer />
            </div>
            <div className={classes.divFooterMobile}>
                <FooterMobile />
            </div>

        </div>
    )
}

export default ListProducts