import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/styles'
import {IconButton, Theme} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import { connect, useDispatch } from 'react-redux'
import {IUser} from '../interfaces/User.interface'
import {setBuyNow} from '../redux/action'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import ScrollNavBar from '../header/NavBarCart'
import SideMenuMobile from '../components/SideMenuMobile'

const useStyles = makeStyles((theme: Theme) => {
    return {
        navRoot:{            
            margin: '0 auto',
            justifyContent: 'center',
            display:'flex',
            marginTop:50,
        },
        menuUl:{
            listStyle: 'none',
            margin: 0,
            padding: 0,
            display: 'flex',
            direction: 'rtl',
        },
        menuItem: {
            width: 200,
            fontWeight: 700,
            fontSize: '17pt',
        },
        menuUlMobile:{
            listStyle: 'none',
            display: 'none',
        },
        logoContainer: {           
            position: 'absolute',
            right: '50px',
            top: '15px',            
        },
        logoTitleContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '-24px',
        },
        logo: {
            fontSize: '24px',
            fontWeight: 'bold',
            color: 'red',         
        },
        logoImg: {            
            width: '300px',
            marginRight: 10,
        },
        hide: {
            display: 'none',
        },
        mobileMenuIcon: {
            width: 48,
            height: 48,
            color: '#2F519C',
        },
        mobileMenuIconContainer:{
            position: 'absolute',
            top: 10,
            left: 10,
            display: 'none',
        },
        mobileTelDiv: {
            fontSize: '2rem',
            marginTop: '13px',
            marginLeft: '20px',
            fontWeight: 500
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: '0, 8',            
            justifyContent: 'flex-start',
        },
        link: {
            textDecoration: 'none', 
            cursor: 'pointer'
        },
        linkTopBar: {
            cursor: 'pointer',
            color: '#000',
            textDecoration: 'unset',
            '&:hover':{
              textDecoration: 'underline',
            }
        },
        mobileNavLeft: {
            width: '250px',
            '& a':{
                textAlign: 'center',
                borderBottom: 'solid 1px #ccc',
            }
        },
        '@media (max-width: 1700px)':{
            logoContainer:{
                right: '70px',
            },
            
        },
        '@media (max-width: 1360px)':{
            menuUl:{
                display: 'none',
            },
            mobileMenuIconContainer:{
                display: 'flex',
            },
            logoContainer: {
                display: 'none',
            },
            menuUlMobile:{                
                display: 'block',
            },
        },
    }
})

export interface INavBarProps{
    user: IUser,
    countItems: number,
}

type Props = INavBarProps & RouteComponentProps

const NavBar: React.FC<Props> =(props) => {
    const classes = useStyles(); 
    const [open, setOpen] = useState(false);
    const [redirect, setRedirect] = useState("");
    const [showScrollBar, seShowScrollBar] = useState(false)
    const {user} = props   
    const countItems: number = props.countItems
    const dispatch = useDispatch()

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const onClickHandler = () =>{
    dispatch(setBuyNow(false))
    props.history.push('/cart')
  }

  const handleScroll = () =>{
      if(window.scrollY >= 200){
        seShowScrollBar(true) 
      }
      else{
        seShowScrollBar(false)
      }  
    //console.log("scrolling: ", window.scrollY)
  }

  const handleDrawerClose =()=>{
    setOpen(false);
  }

  useEffect(()=>{
    if(redirect !== ""){
        window.location.href = redirect
  }

    window.addEventListener('scroll', ()=>{handleScroll()});

  })  
  
    return(
        <div>
            <div className={classes.mobileMenuIconContainer}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerOpen}
                    className={clsx(open && classes.hide)}                    
                >
                    <MenuIcon className={classes.mobileMenuIcon}/>
                </IconButton>                
            </div>
            
            <div className={classes.navRoot + ' container'}>
                <div className={classes.logoContainer}>
                <Link to='/' className={classes.link}>
                    <img src="/images/site/logo.png" alt="" className={classes.logoImg}/>
                </Link>                    
                </div> 
                
                <ul className={classes.menuUl}>                    
                    <li className={classes.menuItem}>
                        <Link to="/howitworks" className={classes.linkTopBar}>
                            איך זה עובד 
                        </Link>                       
                    </li>
                    <li className={classes.menuItem}>
                        <Link to="/products" className={classes.linkTopBar}>
                            תפריט   
                        </Link>
                    </li>
                    <li className={classes.menuItem} style={{'display':'none'}}>הזמנות שלי</li>
                    <li className={classes.menuItem}>058-7-267676</li>
                    <li className={classes.menuItem} style={{'display':'none'}}>
                        {user !== null ? user.name : 
                            <Link to='/login' className={classes.link}>
                                כניסה
                            </Link>
                        }
                    </li>
                </ul>
            </div>
            <SideMenuMobile show={open} onClose={handleDrawerClose} />
            {showScrollBar? <ScrollNavBar showGeneralBtn={true} showHomeBtn={false}/> : ''}            
        </div>
    );
}

const mapStateToProps = (state: any) => {
    console.log('state nav bar: ', state)
    return {
      user: state.userState.user,      
      countItems: state.shoppingCartState.countItems,
    }}

export default withRouter(connect(mapStateToProps)(NavBar))