import { combineReducers } from 'redux';
import products from './products';
import shoppingCart from './shoppingCart';
import dialog from './dialog';
import user from './user';
import admin from './admin';
import alert from './alert';
import contactUs from './contactUs';
import notifications from './notifications';
import messages from './messages';
import orders from './orders'

export default combineReducers({
    productsState: products,
    shoppingCartState: shoppingCart,
    dialogState: dialog,
    userState: user,     
    alertState: alert,
    contactUsState: contactUs,    
    notificationsState: notifications,
    messagesState: messages,
    adminState: admin,
    ordersState: orders,

})