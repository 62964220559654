import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {Close, ShoppingCart as ShoppingCartIcon,} from '@material-ui/icons'
import {AppBar, Toolbar, Link as MLink, IconButton, SwipeableDrawer, List, ListItem, ListItemText, Divider, Badge} from '@material-ui/core'
import { Link, withRouter, RouteComponentProps, } from 'react-router-dom'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme: Theme) => {
    
    return {
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: '0, 8',            
            justifyContent: 'flex-start',
        },
        mobileNavLeft: {
            width: '250px',
            '& a':{
                textAlign: 'center',
                borderBottom: 'solid 1px #ccc',
            }
        },
    }
})

export interface SideMenuMobileProps{
    show: boolean
    onClose: Function
    countItems: number  
}

type Props = SideMenuMobileProps & RouteComponentProps

const SideMenuMobile: React.FC<Props> = (props) => {
    const classes = useStyles(); 
    const [open, setOpen] = useState(props.show);
    const [redirect, setRedirect] = useState("");
    const countItems = props.countItems
    
    const onClickHandler = (path: string) =>{       
         props.history.push(path)
    }

    const closeDrawer = () =>{       
        setOpen(false)
        props.onClose()       
    }

    useEffect(()=>{
        if(redirect !== ""){
            window.location.href = redirect
        }         
        setOpen(props.show)        
    })
    
    return(
        <SwipeableDrawer anchor='left' open={open} onClose={()=> setOpen(false) } onOpen={()=> setOpen(true) }>
            <div className={classes.drawerHeader}>
                <IconButton onClick={closeDrawer}>
                    <Close />
                </IconButton>
            </div>
            <Divider />
            <List component="nav" className={classes.mobileNavLeft} aria-label="secondary mailbox folders">
                <ListItem button component="a" href="tel:058-7-267676">
                    <ListItemText primary="058-7-267676" />
                </ListItem>
                <ListItem button component="a" href="#" onClick={() => setRedirect("/")}>
                    <ListItemText primary="דף הבית" />
                </ListItem>
                <ListItem button component="a" href="#" onClick={() => setRedirect("/howitworks")}>
                    <ListItemText primary="איך זה עובד " />
                </ListItem>
                <ListItem button component="a" href="#" onClick={() => onClickHandler("/products")}>
                    <ListItemText primary="תפריט" />
                </ListItem>
                <ListItem button component="li">
                    <IconButton color="inherit" onClick={()=> { onClickHandler("/cart") }}>
                        <Badge badgeContent={countItems} color="secondary">
                            <ShoppingCartIcon />
                        </Badge>
                    </IconButton>
                </ListItem>
            </List>
        </SwipeableDrawer>
    )
}

const mapStateToProps = (state: any) => {    
    return {     
      countItems: state.shoppingCartState.countItems,
    }
}

export default withRouter(connect(mapStateToProps)(SideMenuMobile))