import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { showDialog, showAlert, requestProducts, editProduct, deleteProduct, requestProductsImages } from '../redux/action';
import store from '../store';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import NavBarAdmin from '../header/NavBarAdmin'
import Toolbar from '@material-ui/core/Toolbar'
import { withRouter, Link } from 'react-router-dom';
import Dialog from '../components/Dialog'
import {ADMIN} from './adminTypes'
import SnackbarsCustom from '../components/SnackbarsCustom';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: '20px 80px 80px 80px', 
        marginTop: '100px',
        direction: 'ltr',     
      },
      tabs: {
          backgroundColor: theme.palette.common.white,
       },
       tabTitle: {
          color: '#000',
       },
       paper: {
          padding: theme.spacing.unit * 2,
          textAlign: 'center',
          color: theme.palette.text.secondary,
        },
        paperLink: {
          display: 'block',
          width: '100%',
          cursor: 'pointer',
        },
        rowGrid: {
          maxHeight: '40px',
          borderBottom: 'solid 1px #ccc',
          padding: '10px',
         
        },
        button: {
         
        },
        newProductButton: {
            fontWeight: 'bold',
            textDecoration: 'underline',
        },
        rowTitle: {
            textAlign: 'left'
        },
        rowsContainer: {
            marginTop: 50,
        },
        column: {
            height: 40
        },
        toolbar: {
            width: '100%',
            borderBottom: 'solid 1px #ccc',
            minHeight: '40px',
            '&:hover':{
                backgroundColor: '#f5f2f2',
            }
        },
  });

  class AdminProducts extends Component{
    constructor(props){
        super(props);
        this.state = {removeProductId: ''};        
    }

    componentDidMount(){
        if(this.props.user === null || this.props.user.permission !== ADMIN){
            this.props.history.push('/login') 
        }
        store.dispatch(requestProducts())
        store.dispatch(requestProductsImages())
    }

    handleEditProduct = (id) =>{
        console.log('edit product: ', id)
        store.dispatch(editProduct(id))
        this.props.history.push('/admin_product_edit')       
    }    

    removeProductDialog = (productId) => {
        let dialogData = { 
            title: 'Warning', 
            text: 'Do you want to remove product ?', 
            buttons: [{event: this.removeProduct, label: 'Remove product'}], 
            show: true 
        }
        //this.state.removeProductId = productId;
        this.setState({removeProductId: productId})
        store.dispatch(showDialog(dialogData));
    }

    removeProduct = () => {        
        this.handlCloseDialog();
        store.dispatch(deleteProduct(this.state.removeProductId));
    }

    handlCloseDialog = (show) => {
        let dialogData = { title: '', text: '', buttons: [], show: false }
        store.dispatch(showDialog(dialogData));
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(showAlert(alert));
    }    

    render(){
        let {classes, products, dialog, alert} = this.props;
        return(
            <>
            <NavBarAdmin />
            <div className={classes.root}>
                 
                {alert.show ? 
                    <SnackbarsCustom data={alert} />
                    :''
                }
               <Dialog dialogData={ dialog } handlDialog={this.handlCloseDialog}/>
               <Grid container spacing={8}>
                    <Link className={classes.button + ' '+ classes.newProductButton} to='/admin_product_new'>
                        New product
                    </Link>
                </Grid>
                <Grid container className={classes.rowsContainer}>
                    {products.map((product, index)=> 
                        <Grid key={index} container >
                            <Toolbar className={classes.toolbar}>                        
                                <Grid item xs={6} sm={8} md={10} className={classes.rowTitle}>
                                    {product.title}
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} >
                                    <Button className={classes.button} onClick={(node) => { this.handleEditProduct(product._id); } }>Edit</Button>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} >
                                    <Button className={classes.button} onClick={(node) => { this.removeProductDialog(product._id); } }>Delete</Button>
                                </Grid>
                            </Toolbar>                           
                        </Grid>
                    )}
                </Grid>
            </div>
            </>
        );
    }
  }

  AdminProducts.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (store) => {
      return {
          user: store.userState.user,
          products: store.productsState.products,
          dialog: store.dialogState.dialog,
          alert: store.alertState.alert,
      }
  }

  export default withRouter(connect(mapStateToProps)(withStyles(styles)(AdminProducts)));