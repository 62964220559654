import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles'
import { Theme, Grid } from "@material-ui/core"
import {connect} from 'react-redux'
import Box from './Box'
import {IProduct} from '../interfaces/Product.interface'
import {useDispatch} from 'react-redux'
import {requestProducts} from '../redux/action'

const useStyles = makeStyles((theme: Theme) => {
    return {
        listBoxRoot: {
            paddingTop: '4rem',
        },
        boxContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        gridContainer:{

        },
        '@media (max-width: 420px)':{
            gridContainer:{
                paddingBottom: '40px',
            },
        }
    }
})

type Props = {products: IProduct[], filterProducts:IProduct[]}

const ListBoxes: React.FC<Props> = (props) => {
    const classes = useStyles()
    //let products: IProduct[] = props.products
    const [products, setProducts] = useState<IProduct[]>(props.products)
    const dispatch = useDispatch()

    useEffect(()=>{
        if(props.products.length === 0){
            dispatch(requestProducts())
        }
        console.log("filter products: ", props.filterProducts)
        console.log("filter products: ", props.filterProducts.length)
        if(props.filterProducts.length !== 0){
            setProducts(props.filterProducts)
        }else{
            setProducts(props.products)
        }
    })
    return(
        <div className={classes.listBoxRoot} id="divMainMenu">
            <div className="container">
                <Grid container spacing={2} className={classes.gridContainer}>
                    {products.map((prod) => {
                        return (
                            <Grid item xs={12} sm={6} md={3} lg={3} className={classes.boxContainer} key={prod._id}>
                                <Box product={prod}/>
                            </Grid> 
                        )
                    })}
                </Grid>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return{
        products: state.productsState.products,
        filterProducts: state.productsState.filterProducts,
        searchText: state.productsState.searchText
    }   
}

export default connect(mapStateToProps)(ListBoxes)


