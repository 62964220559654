import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import NavBar from '../header/NavBarCart'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import FooterMobile from '../components/FooterMobile'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divContainerProducts: {
        marginTop: 120
    },
    divAdvantages:{
        display: 'flex',
        justifyContent: 'center',
    },
    ulAdvantages:{
        listStyleType: 'none',
        textAlign: 'right',
        fontSize: '26px',
        fontWeight: 600,
    },
    liAdvantages:{
        marginTop: 10,
    },
    image:{
        width: '500px',
    },
    divSectionTitle:{
        fontSize: '24px',
        fontWeight: 700,
        margin: '20px 370px 20px 0',
        width: '500px',
        textAlign: 'right',
    },
    spanSectionTitle:{
        textDecoration: 'underline',
    },
    spanNumber:{
        marginLeft: '15px',
        borderRadius: '50%',
        border: 'solid 1px #2F519C',
        padding: '6px 13px',
        backgroundColor: '#2F519C',
        color: 'white',
        fontSize: '18px',
    },
    spanNumberBlue:{
        marginLeft: '15px',
        borderRadius: '50%',
        border: 'solid 3px #2F519C',
        padding: '6px 13px',
        backgroundColor: '#fff',
        color: '#2F519C',
        fontSize: '15px',
        fontWeight: 900,
    },
    divFooter:{
        display: 'block',
    },
    divFooterMobile: {
        display: 'none',
    },
    '@media (max-width: 420px)':{           
        divFooter:{
            display: 'none',
        },
        divFooterMobile: {
            display: 'block',
        },
        divSectionTitle:{
            margin: '20px 10px 20px 0',
            width: 'unset',
        },
        image:{
            width: '100%',
        },
        divAdvantages:{
            marginBottom: '80px',
        },
        divContainerProducts:{
            marginTop: 70,
            '& h1':{
                fontSize: '1.5rem'
            }
        },
    }
  }))

const HowItWorksPage: React.FC = () => {
    const classes = useStyles()
    return (
        <div className={classes.divContainerProducts}>
             <NavBar showGeneralBtn={true} showHomeBtn={false}/>
             <div className="container">
                 <h1>
                 הדרך הכי טובה להכין אוכל זה  
                 </h1>
                 <h1>
                 שף מרקט
                 </h1>
                 <hr></hr>
                 <div>
                     <div className={classes.divSectionTitle}>
                         <span className={classes.spanNumber}>1</span>
                        <span className={classes.spanSectionTitle}>
                            מזמינים ארוחה מהתפריט
                        </span>
                    </div>
                    <Link to="/products">
                        <img className={classes.image} src="/images/site/product_page.png" alt=".."/>
                    </Link>
                </div>
                <div>
                    <div className={classes.divSectionTitle}>
                        <span className={classes.spanNumber}>2</span>
                        <span className={classes.spanSectionTitle}>
                            מקבלים משלוח
                        </span>
                    </div>
                    <Link to="/products">
                        <img className={classes.image} src="/images/site/delivery_package.jpeg" alt=".."/>
                    </Link>
                </div>
                <div>
                    <div className={classes.divSectionTitle}>
                        <span className={classes.spanNumber}>3</span>
                        <span className={classes.spanSectionTitle}>
                            מכינים אוכל 
                        </span>
                    </div>
                    <Link to="/products">
                        <img className={classes.image} src="/images/site/cooking.jpg" alt=".."/>
                    </Link>
                </div>
                <hr></hr>
                <div className={classes.divAdvantages}>
                    <ul className={classes.ulAdvantages}>
                        <li className={classes.spanSectionTitle}>יתרונות להזמין משף מרקט:</li>
                        <li className={classes.liAdvantages}><span className={classes.spanNumberBlue}>1</span>אין צורך ללכת לחנות</li>
                        <li className={classes.liAdvantages}><span className={classes.spanNumberBlue}>2</span>
                            מתכונים מפורטים והוראות הכנה מסודרות
                         </li>
                        <li className={classes.liAdvantages}><span className={classes.spanNumberBlue}>3</span> 
                            ערך קלורי מחושב בכל ארוחה
                        </li>
                        <li className={classes.liAdvantages}><span className={classes.spanNumberBlue}>4</span>הארוחה מוכנה בתוך שעה</li>
                        <li className={classes.liAdvantages}><span className={classes.spanNumberBlue}>5</span> חוסכים כסף, לא קונים יותר ממה שצריך ! </li>
                    </ul>
                </div>               
             </div>
            <div className={classes.divFooter}>
                <Footer />
            </div>
            <div className={classes.divFooterMobile}>
                <FooterMobile />
            </div>
        </div>
    )
}

export default HowItWorksPage