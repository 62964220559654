import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme, Button } from "@material-ui/core"
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import { Link } from '@material-ui/core'
import {IProduct} from '../interfaces/Product.interface'
import {useDispatch, connect} from 'react-redux'
import {editProduct} from '../redux/action'
import { withRouter, RouteComponentProps, Link as LinkRoute } from 'react-router-dom';
import {IShoppingCart, IOrderItem} from '../interfaces/ShoppingCart.interface'
import {setBuyNowProduct} from '../redux/action'

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            maxWidth: 345,
            paddingBottom: 10,
            width: '100%'
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
            minHeight: '60px',
        },
        boxTitle: {
            fontSize: '1.2rem',
            minHeight: '70px',
        },
        boxBtnOrder:{
            backgroundColor: '#2F519C',
            color: '#fff',
            width: '140px',
            height: '40px',
            border: 'solid 1px #2F519C',
            borderRadius: '25px',
            fontSize: '12pt',
            fontWeight: 600,                 
            '&:hover': {
                backgroundColor: '#3b65c3',
            }
        },
        boxCardAction: {
            justifyContent: 'space-around',
        },
        boxSubTitle:{
            display: 'flex',
            justifyContent: 'center'
        },
        boxSubTitleItem: {
            display: 'flex',
            marginLeft: '30px',
        },
        boxCardLink: {

        },
        colorBlack:{
            color:'#000',
        },
        divPriceContainer: {
            fontSize: '30px',
            fontWeight: 700,
        },
    }
})

interface IBoxProps {    
    product: IProduct
    buyNowObj: IShoppingCart
}

type Props = IBoxProps & RouteComponentProps

const Box: React.FC<Props> = (prop) => {
    const classes = useStyles()
    const {product} = prop
    const dispatch = useDispatch()
    //console.log('Box input property: ', prop)
    
    const onClickBoxHandler = (event: React.SyntheticEvent, id: string) => {
        event.preventDefault()
        dispatch(editProduct(id))
        prop.history.push('/product')       
    }
    
    const addToBuyNowCart = () => {
        let buyNowObj: IShoppingCart = prop.buyNowObj       
        
        const orderItem: IOrderItem = {
            item_sku: product.sku,
            title: product.title,
            description: product.description,
            quantity: '1',
            item_price: product.price,
            item_price_total: product.price,            
            image: {link: product.images[0].link, title: product.images[0].title}
        }

        buyNowObj.order_items = []
        buyNowObj.order_items.push(orderItem)
        dispatch(setBuyNowProduct(buyNowObj))
        prop.history.push('/cart')
    }
    return(
        <Card className={classes.root}>
            {/* <Link href="#" style={{textDecoration: 'none', cursor: 'pointer'}} onClick={(e: React.SyntheticEvent) => onClickBoxHandler(e, product._id)}> */}
            <LinkRoute to={"/product/" + product._id} style={{textDecoration: 'none', cursor: 'pointer'}} >
                <CardMedia className={classes.media} image={product.images[0].link} title={product.images[0].title} />
                <CardContent>
                    <Typography className={classes.boxTitle} variant="body2" color="textSecondary" component="p">
                        {product.title}
                    </Typography>
                    <Typography component="div" className={classes.boxSubTitle}>
                    <Typography component="div" className={classes.boxSubTitleItem}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="dish__icon"><circle cx="12" cy="12" r="8" stroke="#7C8289" strokeWidth="1.5"></circle> <path d="M11 7.5C11 7.22386 11.2239 7 11.5 7H12C12.2761 7 12.5 7.22386 12.5 7.5V12.5C12.5 12.7761 12.2761 13 12 13H11.5C11.2239 13 11 12.7761 11 12.5V7.5Z" fill="#7C8289"></path> <path d="M15.5 12C15.7761 12 16 12.2239 16 12.5L16 13C16 13.2761 15.7761 13.5 15.5 13.5L11.5 13.5C11.2239 13.5 11 13.2761 11 13L11 12.5C11 12.2239 11.2239 12 11.5 12L15.5 12Z" fill="#7C8289"></path></svg>
                        <div className={classes.colorBlack}>{product.cookingTime + ' דקות'}</div>
                    </Typography>
                    <Typography component="div" className={classes.boxSubTitleItem}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="dish__icon"><path d="M0 0H24V24H0V0Z" fill="white"></path> <ellipse cx="12" cy="11.5" rx="10" ry="6" stroke="#7C8289" strokeWidth="1.5"></ellipse> <path d="M19 15.5C18.5 17.5 15.3827 19.5 12 19.5C8.61726 19.5 5.5 17.5 5 15.5" stroke="#7C8289" strokeWidth="1.5"></path> <path d="M18.1157 12.6439C17.8759 14.2608 16.3971 14.996 13.7172 14.9836C10.2942 14.9679 8.8148 12.9169 9.05461 11.3C9.29442 9.68304 11.5172 8.67309 14.0194 9.04419C16.5215 9.41529 18.3555 11.0269 18.1157 12.6439Z" stroke="#7C8289" strokeWidth="1.5"></path> <path d="M9.01073 10.7784C7.09311 10.5351 5.42761 11.2123 5.29072 12.291C5.15384 13.3698 6.59741 14.4415 8.51502 14.6848C9.3956 14.7966 10.223 14.7142 10.8737 14.488" stroke="#7C8289" strokeWidth="1.5" strokeLinecap="round"></path></svg>
                        <div className={classes.colorBlack}>{product.weight + ' גרם'}</div>
                    </Typography>
                    </Typography>
                </CardContent>
            </LinkRoute> 
            <CardActions disableSpacing className={classes.boxCardAction}>
                <div className={classes.divPriceContainer}>
                    <span>&#8362;</span>
                    <span>{product.price}</span> 
                </div>                
                <Button className={classes.boxBtnOrder} onClick={addToBuyNowCart}>לקנות</Button>                                
            </CardActions>        
        </Card>
    );
}

const mapStateToProps = (state: any) => {
    return{
        buyNowObj: state.productsState.buyNowObj
    }
}

export default withRouter(connect(mapStateToProps)(Box))