import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles'
import { Theme, Link as MLink } from "@material-ui/core"
import HowItWorks from '../components/HowItWorks'
import Header from '../header/Header'
import ListBoxes from '../components/ListBoxes'
import {useDispatch} from 'react-redux'
import {requestProducts} from '../redux/action'
import Footer from '../components/Footer'
import FooterMobile from '../components/FooterMobile'
import ShopCartLink from '../components/ShopCartLink'

const useStyles = makeStyles((theme: Theme) => {
    return {
        divBGHeader:{
            backgroundImage: 'url(/images/site/header-desk.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '100vh',
            paddingTop: 30,
        },
        backgroundImage:{
            width:'100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left:'0',
            zIndex: -1,
            display: 'none'
        },
        divTopTitle:{
            marginTop:70,
            marginRight: 'auto',
            marginLeft: 'auto',
        },
        homeTopTitleText:{
            position: 'relative',
            marginTop: 0,            
            fontSize: '4.4rem',
            lineHeight: '6.9rem',
            fontWeight: 600,
            color: '#2F519C',
            marginBottom: '0.5rem',
            zIndex: 10,
            textAlign: 'center',            
        },
        homeTopTitleTextSub:{
            marginTop: 0,            
            fontSize: '1.5rem',
            lineHeight: '1rem',
            fontWeight: 600,
            color: '#000',
            marginBottom: '1.6rem',
            textAlign: 'center',            
        },
        homeBtnSelectRecipe:{
            backgroundColor: '#2F519C',
            color: '#fff',
            width: '220px',
            height: '60px',
            border: 'solid 1px #2F519C',
            borderRadius: '25px',
            fontSize: '18pt',
            fontWeight: 600,
            marginTop: 50,
            padding: '12px 50px',       
            '&:hover': {
                backgroundColor: '#3b65c3',
                textDecoration: 'none',
            }
        },
        homeSectionHowItWorks: {
            backgroundColor: '#ECF0F9',
            paddingBottom: '6rem',
            paddingTop: '6rem',
            marginTop: '302px',
        },
        btnSelectRecipeContainer:{
            marginTop: 50,
        },
        divFooter:{
            display: 'block',
        },
        divFooterMobile: {
            display: 'none',
        },
        divMainContainer:{
            
        },
        '@media (max-width: 1350px)':{
            container:{
                width: 'unset',
            },
            homeTopTitleText:{
                fontSize: '2.4rem',
                lineHeight: '3rem',
                textAlign: 'unset',
                marginRight: 'unset',
            },
            homeTopTitleTextSub:{                 
                textAlign: 'unset',
                marginRight: 'unset',               
            },             
        },
        '@media (max-width: 420px)':{            
            homeTopTitleTextSub:{
                display: 'none',                
            },           
            divFooter:{
                display: 'none',
            },
            divFooterMobile: {
                display: 'block',
            },
            divMainContainer:{
                overflowX: 'hidden'
            },     
        }
    }
})

const Home: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(requestProducts())
  })
  return (
    <div  className={classes.divMainContainer}>
        <div style={{}} className={classes.divBGHeader}>            
            <Header/>
            <ShopCartLink/>
            <div className={classes.divTopTitle + ' container'}>           
                <h1 className={classes.homeTopTitleText}>
                    כל אחד שף עם שף מרקט    
                 </h1>
                <p className={classes.homeTopTitleTextSub}>
                    הדרך הטובה ביותר לבשל בבית
                </p>
                <div  className={classes.btnSelectRecipeContainer}>
                    <MLink href="#divMainMenu" className={classes.homeBtnSelectRecipe}>
                    בחירת מתכון  
                    </MLink> 
                </div>
            </div>
        </div>
        <div style={{}}>
            <HowItWorks />
        </div>
        <div className='mb-50'>
            <ListBoxes />
        </div>
        <div className={classes.divFooter}>
            <Footer />
        </div>
        <div className={classes.divFooterMobile}>
            <FooterMobile />
        </div>
    </div>
  );
}

export default Home;
