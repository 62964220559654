import React, { useEffect, useState, ChangeEvent } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles'
import {Grid, Typography, Paper, Tabs, Tab, Box, Link as LinkMaterial, IconButton, Divider} from '@material-ui/core'
import Header from '../header/NavBarEdit'
import {connect, useDispatch} from 'react-redux'
import { IProduct, IDelivery, INeedThinks, IRecommended } from '../interfaces/Product.interface'
import {IShoppingCart, IOrderItem} from '../interfaces/ShoppingCart.interface'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import {setShoppingCart, editProduct} from '../redux/action'
import Footer from '../components/Footer'
import {Close} from '@material-ui/icons'
import FooterMobile from '../components/FooterMobile'
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import ShopCartLink from '../components/ShopCartLink'

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            margin: 0,
            padding: 0,
          },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        dialogActionsRoot: {
            margin: 0,
            padding: theme.spacing(1),
        },
        topImage: {
            width: '100%',
        },
        gridContainer:{
            padding: 20,
            marginTop: 70, 
        },
        gridContainerBottom:{
            padding: 20,
            marginTop: 0, 
        },
        productTitle: {
            fontSize: '40px',
            lineHeight: '46px',
            fontWeight: 900,
        },
        productTitleText: {
            marginBottom: '40px',
            
        },
        boxSubTitle:{
            display: 'flex',
            justifyContent: 'center'
        },
        boxSubTitleItem: {
            display: 'flex',
            marginLeft: '25px',
        },
        separator: {
            'borderBottom': 'solid 1px #ccc',
            'width': '90%',
            margin: '20px auto',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
          },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        textFieldCount: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 100, 
            height: '35px',
            borderTop: 'unset',
            borderRight: 'unset',
            borderLeft: 'unset',
            borderBottom: 'solid 1px #000',
            fontSize: '17px',
            textAlign: 'center',
            outline:0,          
        },
        boxBtnOrder:{
            backgroundColor: '#2F519C',
            color: '#fff',
            width: '140px',            
            border: 'solid 1px #2F519C',
            borderRadius: '25px',
            fontSize: '12pt',
            fontWeight: 600,
            marginLeft: 20,
            padding: '10px 0',
            display: 'inline-block',
            lineHeight: '1rem',
            textDecoration: 'none',                
            '&:hover': {
                backgroundColor: '#3b65c3',
            }
        },
        tabsContainer: {
            flexGrow: 1,
        },
        tabSubContainer: {
            
        },
        dishProductItem: {
            width: '140px',
            height: '140px',
            borderRadius: '50%',
            border: 'solid 1px #fff',
        },
        dishProductTabPanel: {
            backgroundColor: '#f6f6f6',
        },
        gridContainerTab:{
            maxWidth: '920px',           
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        dishTopItem: {
            borderBottom: 'dashed 1px #000',
            marginBottom: '10px',
        }, 
        dishImage: {
            width: '100%',
        },
        price: {
            marginTop: 25,
            fontSize: '2rem',
            fontWeight: 700,
            color: '#2F519C',
        },
        imageContainer:{

        },
        mobilePriceCountContainer:{
            display: 'none'
        },
        mobileButtonsContainer:{
            display: 'none',
        },
        mobileCloseButton:{
            display: 'none',
        },
        divFooter:{
            display: 'block',
        },
        divFooterMobile: {
            display: 'none',
        },
        divOverview: {

        },
        energyTitle: {
            display: 'flex',
        },
        recommendImg: {
            width: '100px',
        },
        recommendTitle:{
            textAlign: 'right',
        },
        recommendTitleItem: {
            fontSize: '1.3rem',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,                       
        },
        recommendedBtnAddShop:{
            marginRight: 'auto',
            marginLeft: '80px',
        },
        linkBtn: {
            cursor: 'pointer',
            color: '#000',
        },
        '@media (max-width: 420px)':{
            dishImage: {
                width: '100%',
                maxHeight: '230px',
            },
            gridContainer:{
                marginTop: 10,
                flexDirection: 'column-reverse',
            },
            productTitle: {
                marginTop: '190px',
                fontSize: '20px',
                lineHeight: 'unset',
            },
            productTitleText: {
                marginBottom: '25px',
                marginTop: '10px',
            },
            imageContainer:{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                padding: '0 !important',
            },
            textFieldCount:{
                width: 60,
                height: 20,
                marginTop: 15,
            },
            mobilePriceCountContainer:{
                display: 'flex',
            },
            price: {
                marginTop: 10,
                marginRight: 20,
                fontSize: '1.5rem',
            },
            mobileButtonsContainer:{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginTop: '30px',
            },
            mobileCloseButton:{
                position: 'absolute',
                top:' 10px',
                right: '10px',              
                backgroundColor: '#fff',
                borderRadius: '50%',
                opacity: 0.7,
                display: 'flex',
            },
            divFooter:{
                display: 'none',
            },
            divFooterMobile: {
                display: 'block',
            },
            divOverview: {
                marginBottom: '50px',
            },
            separator:{
                margin: '10px auto',
                width: '100%',
            },
            energyTitle: {
                display: 'none',
            },
            recommendedBtnAddShop:{                
                marginLeft: 'unset',
            },
        },
    }
})

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;    
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{'backgroundColor':'#f8f7f7'}}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  interface IBoxEditProps{
    product: IProduct
    shoppingCart: IShoppingCart
    products: IProduct[]
  }

  type Props = IBoxEditProps & RouteComponentProps

const BoxEdit: React.FC<Props> = (props) => {   
    const classes = useStyles()
    const dispatch = useDispatch()
    const [currentTab, setCurrentTab] = React.useState(0)
    const [count, setCount] = useState('1')   
    const [price, setPrice] = useState(0)
    const [scroll, setScroll] = useState(true)
    const product: IProduct = props.product
    console.log('product edit: ', product)    
    
    if(props.product !== null && price === 0){
        setPrice(props.product.price)
    }

    useEffect(()=>{
        if(scroll){
            window.scrollTo(0, 0)
            setScroll(false)
        }        
        document.title = props.product !== null? props.product.title : 'כל אחד שף עם שף מרקט'
    })
    
    if(product === null){
        return (<span>טוען</span>)
    }

    const weDelivery: IDelivery[] = props.product.delivery
    const needThinks: INeedThinks[] = props.product.needThinks
    const recommended: IRecommended[] = props.product.recommended    
     
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue)
    };

    const addToShoppingCart = () => {
        let shoppingCart: IShoppingCart = props.shoppingCart
        const total = product.price * parseInt(count)

        const orderItem: IOrderItem = {
            item_sku: product.sku,
            title: product.title,
            description: product.description,
            item_price: product.price,
            item_price_total: total,
            quantity: count,
            image: {link: product.images[0].link, title: product.images[0].title}
        }

        shoppingCart.order_items.push(orderItem)
        dispatch(setShoppingCart(shoppingCart))
        //props.history.push('/cart')
    }

    const addToShoppingCartRecommend = (id: string) => {
        let shoppingCart: IShoppingCart = props.shoppingCart
        let productRecommend: IProduct = props.products.filter(p=>p._id === id)[0]
        const total = product.price * parseInt(count)

        const orderItem: IOrderItem = {
            item_sku: productRecommend.sku,
            title: productRecommend.title,
            description: productRecommend.description,
            item_price: productRecommend.price,
            item_price_total: productRecommend.price,
            quantity: '1',
            image: {link: productRecommend.images[0].link, title: productRecommend.images[0].title}
        }

        shoppingCart.order_items.push(orderItem)
        dispatch(setShoppingCart(shoppingCart))
        //props.history.push('/cart')
    }

    const mobileCloseEdit = () => {
        props.history.push('/#divMainMenu')
    }

    const mobileSetCount = (e: ChangeEvent<HTMLInputElement>) => {
        setCount(e.target.value)
        if(e.target.value !== ''){
            let priceProduct = product.price * parseInt(e.target.value)
            setPrice(priceProduct)
        }       
    }

    const redirectToRecommend = (id: string) => {
        dispatch(editProduct(id))
        props.history.push('/product')
    }  

    return (
        <div className='container'>
            <Header />
            <ShopCartLink/>
            <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} sm={5} md={5} lg={5} className={classes.productTitle}>
                    <Grid className={classes.productTitleText}>
                        {product.title} 
                    </Grid>
                    <Typography component="div" className={classes.boxSubTitle}>
                        <Typography component="div" className={classes.boxSubTitleItem}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="dish__icon"><circle cx="12" cy="12" r="8" stroke="#7C8289" strokeWidth="1.5"></circle> <path d="M11 7.5C11 7.22386 11.2239 7 11.5 7H12C12.2761 7 12.5 7.22386 12.5 7.5V12.5C12.5 12.7761 12.2761 13 12 13H11.5C11.2239 13 11 12.7761 11 12.5V7.5Z" fill="#7C8289"></path> <path d="M15.5 12C15.7761 12 16 12.2239 16 12.5L16 13C16 13.2761 15.7761 13.5 15.5 13.5L11.5 13.5C11.2239 13.5 11 13.2761 11 13L11 12.5C11 12.2239 11.2239 12 11.5 12L15.5 12Z" fill="#7C8289"></path></svg>
                            <div>{product.cookingTime} דקות</div>
                        </Typography>
                        <Typography component="div" className={classes.boxSubTitleItem}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="dish__icon"><path d="M0 0H24V24H0V0Z" fill="white"></path> <ellipse cx="12" cy="11.5" rx="10" ry="6" stroke="#7C8289" strokeWidth="1.5"></ellipse> <path d="M19 15.5C18.5 17.5 15.3827 19.5 12 19.5C8.61726 19.5 5.5 17.5 5 15.5" stroke="#7C8289" strokeWidth="1.5"></path> <path d="M18.1157 12.6439C17.8759 14.2608 16.3971 14.996 13.7172 14.9836C10.2942 14.9679 8.8148 12.9169 9.05461 11.3C9.29442 9.68304 11.5172 8.67309 14.0194 9.04419C16.5215 9.41529 18.3555 11.0269 18.1157 12.6439Z" stroke="#7C8289" strokeWidth="1.5"></path> <path d="M9.01073 10.7784C7.09311 10.5351 5.42761 11.2123 5.29072 12.291C5.15384 13.3698 6.59741 14.4415 8.51502 14.6848C9.3956 14.7966 10.223 14.7142 10.8737 14.488" stroke="#7C8289" strokeWidth="1.5" strokeLinecap="round"></path></svg>
                            <div>{product.weight} גר</div>
                        </Typography>
                        <Typography component="div" className={classes.boxSubTitleItem}>
                            <div>{product.cookingLevel} להכין</div>
                        </Typography>
                    </Typography>                 
                    <Typography component="div" className={classes.separator}></Typography>                    
                    <Typography component="div" className={classes.boxSubTitle + ' mb-15 ' + classes.energyTitle}>
                        ערך תזונה ואנרגיה
                     </Typography>
                     <Typography component="div" className={classes.boxSubTitle}>
                        <Typography component="div" className={classes.boxSubTitleItem}>
                             {`חלבון: ${product.proteins}`}                            
                        </Typography>
                        <Typography component="div" className={classes.boxSubTitleItem}>
                            {`פחמימות: ${product.carbohydrates}`}                           
                        </Typography>
                        <Typography component="div" className={classes.boxSubTitleItem}>
                            {`שומנים: ${product.fats}`} 
                        </Typography>
                        <Typography component="div" className={classes.boxSubTitleItem}>
                            {`קלוריות: ${product.calories}`} 
                        </Typography>
                    </Typography>
                    <Typography component="div" className={classes.separator}></Typography>
                    {recommended.length > 0 ?
                    <>
                        <Typography component="div" className={classes.boxSubTitle + ' mb-15 ' + classes.energyTitle}>
                            מומלץ עם מנה
                        </Typography>
                        {recommended.map((recom)=>{
                            return (
                                <Grid container spacing={2} className={classes.gridContainerTab} key={recom.rec_product_id}>
                                    <Grid item xs={4} sm={3} md={3} lg={3}>
                                        <LinkMaterial className={classes.linkBtn} onClick={()=> {redirectToRecommend(recom.rec_product_id)}}>
                                            <img src={recom.rec_image} alt={recom.rec_title} className={classes.recommendImg}/>
                                        </LinkMaterial>
                                    </Grid>
                                    <Grid item xs={8} sm={9} md={9} lg={9} className={classes.recommendTitle + ' d-flex'}>
                                        <LinkMaterial className={classes.linkBtn} onClick={()=> {redirectToRecommend(recom.rec_product_id)}}>
                                            <span className={classes.recommendTitleItem}>{recom.rec_title}</span>
                                        </LinkMaterial>                                    
                                        <div className={classes.recommendedBtnAddShop}>
                                            <IconButton color="inherit" onClick={()=>{addToShoppingCartRecommend(recom.rec_product_id)}} title='להוסיף לסל'>
                                                <AddShoppingCart />
                                            </IconButton>
                                        </div>
                                    </Grid>
                                </Grid>
                            )
                        })}
                        <Typography component="div" className={classes.separator}></Typography>
                    </>
                    :''}                                                    
                    <Typography component="div" className={classes.boxSubTitle +' '+ classes.mobilePriceCountContainer} >
                        <Typography component="div" className={'mt-20'}>
                            כמות:
                        </Typography>
                        <Typography component="div">
                            <input type="number" min='1' max='100' value={count} className={classes.textFieldCount} onChange={(e)=>{ mobileSetCount(e) }}/>
                        </Typography>
                        <Typography component="div" className={classes.price}>
                            {`${price} ש"ח`}
                        </Typography>
                    </Typography>                    
                    <Typography component="div" className={classes.mobileButtonsContainer}>
                        <Link to='/' className={classes.boxBtnOrder}>לקנות</Link>
                        <LinkMaterial href="#" className={classes.boxBtnOrder} onClick={addToShoppingCart}>להוסיף לסל</LinkMaterial>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={7} md={7} lg={7} className={classes.imageContainer}>
                    <div className={classes.mobileCloseButton}>
                        <IconButton onClick={() => mobileCloseEdit()}>                       
                            <Close />
                        </IconButton>
                    </div>
                    <img src={product.images[0].link} alt={product.images[0].title} className={classes.dishImage}/>
                </Grid>
            </Grid> 
            <Grid container spacing={2} className={classes.gridContainerBottom + ' ' + classes.divOverview}>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.tabSubContainer} >
                    <Paper square className={classes.tabsContainer}>
                        <Tabs
                            value={currentTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChangeTab}
                            aria-label="disabled tabs example"
                            centered
                        >
                            <Tab label="אנו מביאים" />
                            <Tab label="צריך להיות בבית" />
                            <Tab label="מתכון" />
                        </Tabs>
                        <TabPanel value={currentTab} index={0}>
                            <Grid container spacing={2} className={classes.gridContainerTab}>
                                {weDelivery.map((deliveryItem, index)=>{
                                    return (
                                        <Grid item xs={6} sm={3} md={3} lg={3} className={classes.tabSubContainer} key={'del_'+index}>
                                            <img src={deliveryItem.picture} alt={deliveryItem.title} className={classes.dishProductItem}/>
                                            <div className={classes.dishTopItem}>
                                                {deliveryItem.title}
                                            </div>                                    
                                            <div style={{display: 'none'}}>
                                                {`${deliveryItem.count} ${deliveryItem.measure}`}
                                            </div>
                                        </Grid>
                                    )
                                })}                                
                            </Grid>
                        </TabPanel>
                        <TabPanel value={currentTab} index={1}>
                            <Grid container spacing={2} className={classes.gridContainerTab}>
                                {needThinks.map((needItem, index)=>{
                                    return (
                                        <Grid item xs={6} sm={3} md={3} lg={3} className={classes.tabSubContainer} key={'need_'+ index}>
                                            <img src={needItem.picture} alt={needItem.title} className={classes.dishProductItem}/>
                                            <div className={classes.dishTopItem}>
                                                {needItem.title}
                                            </div>                                   
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </TabPanel>
                        <TabPanel value={currentTab} index={2}>
                            <div>
                                {product.recipe.title}
                            </div>
                            <div>
                                {product.recipe.text}
                            </div>
                        </TabPanel>
                    </Paper>
                </Grid>
            </Grid>
            <div className={classes.divFooter}>
                <Footer />
            </div>
            <div className={classes.divFooterMobile}>
                <FooterMobile />
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return{
        product: state.productsState.editProduct,
        shoppingCart: state.shoppingCartState.shoppingCart,
        products: state.productsState.products,
    }
}

export default withRouter(connect(mapStateToProps)(BoxEdit))