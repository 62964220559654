import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {showDialog, showAlert, addProduct} from '../redux/action';
import store from '../store';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save';
import Add from '@material-ui/icons/Add';
//import Dialog from './../Dialog';
import SnackbarsCustom from '../components/SnackbarsCustom';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {Link, withRouter} from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import {ADMIN} from './adminTypes'
import Dialog from '../components/Dialog'
import MenuItem from '@material-ui/core/MenuItem'
import config from '../config'


const styles = theme => ({
    root: {
      flexGrow: 1,
      padding: '60px 80px 80px 80px',
      maxWidth: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',
      direction: 'ltr',     
    },
    tabs: {
        backgroundColor: theme.palette.common.white,
     },
    tabTitle: {
        color: '#000',
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperLink: {
        display: 'block',
        width: '100%',
        cursor: 'pointer',
    },
    textfield: {    
        width: '100%',
        fontSize: '15px',
    },
    margin: {
        margin: theme.spacing.unit,
    },
    labelSpan:{ 
        marginTop: '13px',
        fontSize: '17px',
        width: '150px',
    },
    gridItem:{
        display: 'inline-flex' 
    },
    separatorGrid:{
        marginTop: 20,
        marginBottom: 10,
        textAlign: 'center',
        borderBottom: 'solid 1px #ccc',
        fontWeight: 'bold',
    },
    multiSelectTitle:{
          marginTop: '20px',
          fontSize: '17px',
          fontWeight: 'bold',         
    },
    imageGrid:{
          marginTop: 20,
    },
    buttonSave: {
        margin: theme.spacing.unit,
        minWidth: "140px",
        fontSize: "14px",
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonSaveTop: {
        margin: theme.spacing.unit,
        minWidth: "140px",
        fontSize: "14px",
        marginLeft: 'auto',        
    },
    backToListButton: {
        fontWeight: 'bold',
        textDecoration: 'underline',
        display: 'flex',
    },
    productImage:{
        maxWidth: 320,
        maxHeight: 200,
        width: 'auto',
        height: 'auto',
    },
    toolbar: {
        width: '100%',
        borderBottom: 'solid 1px #ccc',
        minHeight: '40px',
        '&:hover':{
            backgroundColor: '#f5f2f2',
        }
    },
    deliveryRowBtn: {
        marginLeft: 'auto',
    },
    imageList:{
        width: 60,
    },
    width100Pr:{
        width: '100%',
    }
  });

class AdminPorductNew extends Component{
    constructor(){
        super();
        this.state = {
            imageLink: '', 
            imageTitle: '', 
            cbEnable: true, 
            cbIsFreeShipping: true, 
            arrDelivery: [], 
            delivery:{id: '', title: '', count: '', measure: '', description: '', picture: '' },
            arrNeed: [],
            need: {id: '', title: '', description: '', picture: ''},
            product: {},
            images: [],
            image: {title: '', link: ''},
            recipe: {title: '', text: ''},
            arrRecommended: []
        };
    }

    onTextChange = (e) => {
        let id = e.target.id === undefined ? e.target.name:e.target.id    
        this.setState({product: {...this.state.product, [id]: e.target.value}})
    }

    onTextChangeRecipe = (e) => {
        const fieldId = e.target.id.split('_')[1]
        this.setState({recipe: {...this.state.recipe, [fieldId]: e.target.value}})
    }

    handleAddImage = () =>{        
        this.setState(prev => {
            const images = prev.images; 
            images.push(prev.image);
            return {images: images, image: {title: '', link: ''}}
        })       
    }

    handleRemoveImage = (e, image) =>{
        const arrImg = this.state.images.filter(i => i.title !== image.title && i.link !== image.link)
        this.setState({images: arrImg})
    }    

    handleSaveRespoceImageDrop = (err, result) =>{
        let alert={};
        if(err){
            alert = {text: 'Faild save product image, error: ' + err, variant: 'error', show: true, event: this.onAlertClose }
            store.dispatch(showAlert(alert));
        }else if(result.data.success !== true){
            alert = {text: 'Failed save product image, error: ' + result.data.text, variant: 'error', show: true, event: this.onAlertClose }
            store.dispatch(showAlert(alert));
        }
    }
    
    handleImageTextChange = (e) =>{  
        const fieldId = e.target.id.split('_')[1]      
        this.setState({image: {...this.state.image, [fieldId]: e.target.value}});
    }

    handleImageChange = (e) =>{ 
        this.setState({image: {...this.state.image, ["link"]: e.target.value}});
    }

    handleSaveProduct = () => {
        let arrNotValid = [];

        const newProduct = this.state.product

        if(newProduct.sku === undefined || newProduct.sku === ''){
            arrNotValid.push('Sku');
        }
        if(newProduct.title === undefined || newProduct.title === ''){
            arrNotValid.push('Title');
        }
        if(newProduct.description === undefined || newProduct.description === ''){
            arrNotValid.push('Description');
        }
        if(newProduct.price === undefined || newProduct.price === ''){
            arrNotValid.push('Price');
        }
        if(newProduct.cookingTime === undefined || newProduct.cookingTime === ''){
            arrNotValid.push('Cooking Time');
        }
        if(newProduct.weight === undefined || newProduct.weight === ''){
            arrNotValid.push('weight');
        }
        if(newProduct.cookingLevel === undefined || newProduct.cookingLevel === ''){
            arrNotValid.push('Cooking Level');
        }
        if(newProduct.proteins === undefined || newProduct.proteins === ''){
            arrNotValid.push('proteins');
        }
        if(newProduct.fats === undefined || newProduct.fats === ''){
            arrNotValid.push('Fats');
        }
        if(newProduct.carbohydrates === undefined || newProduct.carbohydrates === ''){
            arrNotValid.push('carbohydrates');
        }
       
        if(this.state.images === undefined || this.state.images.length === 0){
            arrNotValid.push('Images');
        }
        if(newProduct.isFreeShipping === false){
            if(newProduct.shippingCost === undefined || newProduct.shippingCost === ''){
                arrNotValid.push('Shipping Cost');
            }
        }
        
        console.log('save product')
        if(arrNotValid.length > 0){
            console.log('save product not valid: ', arrNotValid)
            let dialogData = { 
                title: 'Warning', 
                text: 'Required fields are not filled: ' + arrNotValid.join(', '), 
                buttons: [], 
                show: true 
            }            
            store.dispatch(showDialog(dialogData));
        }else{              
            newProduct.delivery = this.state.arrDelivery
            newProduct.needThinks = this.state.arrNeed
            newProduct.images = this.state.images
            newProduct.recipe = this.state.recipe
            newProduct.enable = this.state.cbEnable
            newProduct.isFreeShipping = this.state.cbIsFreeShipping
            newProduct.recommended = this.state.arrRecommended
            console.log('save product before save object: ', newProduct)
            store.dispatch(addProduct(newProduct))
            this.props.history.push('/admin_products')        
        }
    }

    handlCloseDialog = (show) => {
        let dialogData = { title: '', text: '', buttons: [], show: false }
        store.dispatch(showDialog(dialogData));
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(showAlert(alert));
    }

    handleChange = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    onTextChangeDelivery = (e) => {
        const fieldId = e.target.id.split('_')[1]
        this.setState({delivery: {...this.state.delivery, [fieldId]: e.target.value}});
    }

    onImageChangeDelivery = (e) => {
        //const filePath = config.params.image_path + '/' + e.target.value            
        this.setState({delivery: {...this.state.delivery, ['picture']: e.target.value}});
    }

    handleAddDelivery = () => {
        const emptydelivery = {id: '', title: '', count: '', measure: '', description: '', picture: '' }
        
        if(this.state.delivery.id !== ''){
            console.log('remove delivery: ', this.state.delivery.id, ', ', this.state.delivery.id !== '')            
            this.setState(prev => {
                let arrDelivery = prev.arrDelivery.filter(d => d.id !== prev.delivery.id)
                arrDelivery.push(prev.delivery)
                return {arrDelivery: arrDelivery, delivery: emptydelivery}
            })
        }else{
            let obj = this.state.delivery
            obj.id = this.getRndInteger(false)
            console.log("before add delivery: ", obj)
            this.setState({arrDelivery: [...this.state.arrDelivery, obj], delivery: emptydelivery})
        }        
    }

    handleEditDelivery = (id) => {
        let delivery = this.state.arrDelivery.filter(d => d.id === id)[0]
        this.setState({delivery: delivery})
    }

    handleDeleteDelivery = (id) => {
        let arrDelivery = this.state.arrDelivery.filter(d => d.id !== id)
        this.setState({arrDelivery: arrDelivery})
    }

    handleAddNeed = () => {
        const emptyneed = {id: '', title: '', description: '', picture: '' }
        
        if(this.state.need.id !== ''){            
            this.setState(prev => {
                let arrNeed = prev.arrNeed.filter(d => d.id !== prev.need.id)
                arrNeed.push(prev.need)
                return {arrNeed: arrNeed, need: emptyneed}
            })
        }else{
            let obj = this.state.need
            obj.id = this.getRndInteger(false)
            console.log("before add need: ", obj)
            this.setState({arrNeed: [...this.state.arrNeed, obj], need: emptyneed})
        }        
    }

    handleEditNeed = (id) => {
        let need = this.state.arrNeed.filter(d => d.id === id)[0]
        this.setState({need: need})
    }

    handleDeleteNeed = (id) => {
        let arrNeed = this.state.arrNeed.filter(d => d.id !== id)
        this.setState({arrNeed: arrNeed})
    }

    onTextChangeNeed = (e) => {
        const fieldId = e.target.id.split('_')[1]
        this.setState({need: {...this.state.need, [fieldId]: e.target.value}});
    }

    onImageChangeNeed = (e) => {        
        this.setState({need: {...this.state.need, ['picture']: e.target.value}});
    }    

    getRndInteger = (isSku) => {
        const sku = Math.floor(Math.random() * (10000000 - 1000)) + 1000
        if(isSku){
            this.setState({product: {...this.state.product, ['sku']: sku}})
        }        
        return sku
    }

    componentWillMount(){
        if(this.props.user === null || this.props.user.permission !== ADMIN){
            this.props.history.push('/login') 
        }
        this.getRndInteger(true)
    }
    
    onTextChangeRecommended = (e) => {
        const {products} = this.props
        const prod = products.filter(p => p._id === e.target.value)[0]
        const objRec = { rec_product_id: prod._id, rec_title: prod.title, rec_image: prod.images[0].link}
        this.setState({arrRecommended: [...this.state.arrRecommended, objRec]})
        console.log('state recommended: ', this.state)
    }

    handleDeleteRecommend = (id) => {
        let arrRecommended = this.state.arrRecommended.filter(d => d.rec_product_id !== id)
        this.setState({arrRecommended: arrRecommended})
    }
    
    render(){
        let {classes, alert, dialog, products, productImages} = this.props;
        const {delivery, arrDelivery, arrNeed, need, images, product, image} = this.state                
        console.log('deliver state: ', this.state)       
        if(alert.show){
            setTimeout(this.onAlertClose, 2000)
        }
        return(
            <div>
                {alert.show ? 
                    <SnackbarsCustom data={alert} />
                    :''
                }
                <Dialog dialogData={ dialog } handlDialog={this.handlCloseDialog}/>
                <div className={classes.root}>                    
                    <Grid container spacing={2}>
                        <Link className={classes.backToListButton} to='/admin_products'>
                            <KeyboardArrowLeft />
                            <span>Back to list</span> 
                        </Link>
                    </Grid>
                    <Grid item sm={12} className={classes.gridItem +' '+ classes.width100Pr}>
                        <Button variant="contained" color="primary" className={classes.buttonSaveTop} onClick={this.handleSaveProduct}>
                            Save
                            <SaveIcon className={classes.rightIcon} />
                        </Button>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item sm={12} className={classes.separatorGrid}>
                            <span className={classes.labelSpan}>New product</span>
                        </Grid>                        
                        <Grid item sm={12} className={classes.gridItem}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={this.state.cbEnable}
                                onChange={this.handleChange}
                                name="cbEnable"
                                color="primary"
                            />
                            }
                            label="Enable"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={this.state.cbIsFreeShipping}
                                onChange={this.handleChange}
                                name="cbIsFreeShipping"
                                color="primary"
                            />
                            }
                            label="Free shipping"
                        />
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="title"
                                label="Title"                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>  
                        <Grid item sm={2} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="sku"
                                label="SKU"                                                               
                                value={this.state.product.sku}
                                disabled
                            />           
                        </Grid>
                        <Grid item sm={1} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="price"
                                label="Price"                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid> 
                        <Grid item sm={2} className={classes.gridItem}>                        
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="shippingCost"
                                label="Shipping Cost"                                
                                onChange={(node) => this.onTextChange(node)}
                            />                                           
                        </Grid>                                           
                        <Grid item sm={1} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="cookingTime"
                                label="Time"                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>

                        <Grid item sm={2} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="weight"
                                label="Weight"                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>

                        <Grid item sm={2} className={classes.gridItem}>                           
                            <TextField
                                className={classes.margin+' '+classes.textfield}                               
                                label="Cooking Level"
                                name="cookingLevel"
                                onChange={(node) => this.onTextChange(node)}                                
                                select
                                value={product.cookingLevel === undefined ? "0" : product.cookingLevel }
                            >
                                <MenuItem value="קל">
                                    קל
                                </MenuItem>
                                <MenuItem value="בנוני">
                                    בנוני   
                                </MenuItem>
                                <MenuItem value="קשה">
                                    קשה
                                </MenuItem>
                            </TextField>
                        </Grid>
                        
                        <Grid item sm={2} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="proteins"
                                label="Proteins"                                
                                onChange={(node) => this.onTextChange(node)}
                            />  
                        </Grid>
                        <Grid item sm={2} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="fats"
                                label="Fats"                                
                                onChange={(node) => this.onTextChange(node)}
                            />  
                        </Grid>
                        <Grid item sm={2} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="carbohydrates"
                                label="Carbohydrates"                                
                                onChange={(node) => this.onTextChange(node)}
                            />  
                        </Grid>
                        <Grid item sm={2} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="calories"
                                label="Calories"                                
                                onChange={(node) => this.onTextChange(node)}
                                value={product.calories}
                            />  
                        </Grid>
                        
                        <Grid item sm={12} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="description"
                                label="Description"                                                                
                                onChange={(node) => this.onTextChange(node)}
                                multiline
                                rowsMax="6"
                                margin="normal"              
                                variant="outlined"
                            />           
                        </Grid>                          
                    </Grid>
                    <Grid container spacing={2} className={classes.imageGrid}>
                        <Grid item sm={12} className={classes.separatorGrid}>
                            <span className={classes.labelSpan}>We Delivery</span>
                        </Grid>
                        <Grid item sm={4} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="delivery_title"
                                label="Delivery Title"
                                onChange={(node) => this.onTextChangeDelivery(node)}
                                value={delivery.title}
                            />                                           
                        </Grid>
                        <Grid item sm={4} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                name="delivery_picture"
                                label="Delivery picture"                                
                                onChange={(node) => this.onImageChangeDelivery(node)}
                                value={delivery.picture !== undefined ? delivery.picture: '' }
                                select
                            >
                            {productImages.ingredients.map((img, index) => {
                                return <MenuItem key={img+'_' + index} value={config.params.image_path + '/ingredients/' + img}>
                                            {img}
                                        </MenuItem>                                                                       
                                })}
                            </TextField>                                           
                        </Grid>
                        <Grid item sm={2} className={classes.gridItem}>
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="delivery_count"
                                label="Delivery count"                                
                                onChange={(node) => this.onTextChangeDelivery(node)}
                                value={delivery.count}
                            />                                           
                        </Grid>
                        <Grid item sm={2} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="delivery_measure"
                                label="Delivery measure"                                
                                onChange={(node) => this.onTextChangeDelivery(node)}
                                value={delivery.measure}
                            />                                           
                        </Grid>                       
                        <Grid item sm={12} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="delivery_description"
                                label="Delivery description"                                
                                onChange={(node) => this.onTextChangeDelivery(node)}
                                value={delivery.description}
                            />
                        </Grid>
                        <Grid item sm={12} className={classes.gridItem}>
                            <Button variant="contained" color="primary" className={classes.buttonSave} onClick={this.handleAddDelivery}>
                                 Add
                                <Add className={classes.rightIcon} />
                            </Button>
                        </Grid>
                        {arrDelivery.map((del, index)=>{
                            return(
                            <Grid container spacing={2} className={classes.imageGrid} key={index}>
                                <Toolbar className={classes.toolbar}>
                                <Grid item sm={2} className={classes.gridItem}>                            
                                    <img src={del.picture} alt={del.title} className={classes.imageList}/>
                                </Grid>
                                <Grid item sm={6} className={classes.gridItem}>                            
                                    {del.title}
                                </Grid>                               
                                <Grid item sm={4} className={classes.gridItem}> 
                                    <div className={classes.deliveryRowBtn}>
                                    <Button onClick={() => {this.handleEditDelivery(del.id)}}>
                                        <Edit />
                                    </Button>                           
                                    <Button onClick={() => {this.handleDeleteDelivery(del.id)}}>
                                        <Delete />
                                    </Button>
                                    </div>
                                    
                                </Grid>
                                </Toolbar>
                            </Grid>)
                        })}
                        
                    </Grid>
                    <Grid container spacing={2} className={classes.imageGrid}>
                        <Grid item sm={12} className={classes.separatorGrid}>
                            <span className={classes.labelSpan}>Need for cooking</span>
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="needThinks_title"
                                label="Need Title"                                
                                onChange={(node) => this.onTextChangeNeed(node)}
                                value={need.title}
                            />                                           
                        </Grid>                      
                        <Grid item sm={6} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                name="needThinks_picture"
                                label="Need picture"                                
                                onChange={(node) => this.onImageChangeNeed(node)}
                                value={need.picture !== undefined ? need.picture: '' }
                                select
                            >
                            {productImages.need.map((img, index) => {
                                return <MenuItem key={img+'_' + index} value={config.params.image_path + '/need/' + img}>
                                            {img}
                                        </MenuItem>                                                                       
                                })}
                            </TextField>                                           
                        </Grid>                       
                        <Grid item sm={12} className={classes.gridItem}>
                            <Button variant="contained" color="primary" className={classes.buttonSave} onClick={() => {this.handleAddNeed()}}>
                                 Add
                                <Add className={classes.rightIcon} />
                            </Button>
                        </Grid>
                        {arrNeed.map((need, index)=>{
                            return(
                            <Grid container spacing={2} className={classes.imageGrid} key={index}>
                                <Toolbar className={classes.toolbar}>
                                <Grid item sm={2} className={classes.gridItem}>                            
                                    <img src={need.picture} alt={need.title} className={classes.imageList}/>
                                </Grid>
                                <Grid item sm={6} className={classes.gridItem}>                            
                                    {need.title}
                                </Grid>                               
                                <Grid item sm={4} className={classes.gridItem}> 
                                    <div className={classes.deliveryRowBtn}>
                                    <Button onClick={() => {this.handleEditNeed(need.id)}}>
                                        <Edit />
                                    </Button>                           
                                    <Button onClick={() => {this.handleDeleteNeed(need.id)}}>
                                        <Delete />
                                    </Button>
                                    </div>
                                    
                                </Grid>
                                </Toolbar>
                            </Grid>)
                        })}
                    </Grid>
                    <Grid container spacing={2} className={classes.imageGrid}>
                        <Grid item sm={12} className={classes.separatorGrid}>
                            <span className={classes.labelSpan}>Cooking Recipe</span>
                        </Grid>
                        <Grid item sm={12} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="recipe_title"
                                label="Recipe Title"                                
                                onChange={(node) => this.onTextChangeRecipe(node)}
                            />                                           
                        </Grid>
                        <Grid item sm={12} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id="recipe_text"
                                label="Recipe description"                                
                                onChange={(node) => this.onTextChangeRecipe(node)}
                            />                                           
                        </Grid>
                    </Grid>                                   
                    <Grid container spacing={2} className={classes.imageGrid}>
                        <Grid item sm={12} className={classes.separatorGrid}>
                            <span className={classes.labelSpan}>Images</span>
                        </Grid>
                        <Grid item sm={2} className={classes.gridItem}>                                
                                <TextField
                                    className={classes.margin+' '+classes.textfield}
                                    id="image_title"
                                    label="Image name"                                    
                                    onChange={this.handleImageTextChange}                                                               
                                />           
                        </Grid>
                        <Grid item sm={10} className={classes.gridItem}>                                
                                <TextField
                                    className={classes.margin+' '+classes.textfield}
                                    id="image_link"
                                    label="Image link"                                    
                                    onChange={this.handleImageTextChange}
                                />           
                        </Grid>
                        <Grid item sm={12} className={classes.gridItem}>                                
                        <TextField
                                className={classes.margin+' '+classes.textfield}
                                name="image_link_select"
                                label="Image link"                                
                                onChange={(node) => this.handleImageChange(node)}
                                value={image.link !== undefined ? image.link: '' }
                                select
                            >
                            {productImages.products.map((img, index) => {
                                return <MenuItem key={img+'_' + index} value={config.params.image_path + '/products/' + img}>
                                            {img}
                                        </MenuItem>                                                                       
                                })}
                            </TextField>           
                        </Grid>
                        <Grid item sm={12} className={classes.gridItem}>
                            <Button variant="contained" color="primary" className={classes.buttonSave} onClick={() => {this.handleAddImage()}}>
                                 Add
                                <Add className={classes.rightIcon} />
                            </Button>
                        </Grid>                        
                    </Grid>                    
                    <Grid container spacing={2}>
                        {images.map((image, index) =>
                             <Grid key={index} item sm={4} className={classes.gridItem}>
                                <Card className={classes.card}>
                                    <div>
                                        <img className={classes.productImage} alt={image.title} src={image.link} />
                                    </div>
                                    <CardActions className={classes.actions} disableActionSpacing>
                                    <IconButton aria-label="Delete Image" 
                                        onClick={(node) => {
                                            let obj = {title: image.title, link: image.link}; 
                                            this.handleRemoveImage(node, obj);
                                            }}>
                                        <Delete />
                                    </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container spacing={2} className={classes.imageGrid}>
                        <Grid item sm={12} className={classes.separatorGrid}>
                            <span className={classes.labelSpan}>Recommended dishes</span>
                        </Grid>
                        <Grid item sm={12} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                id=""
                                label="Recommended"
                                onChange={(node) => this.onTextChangeRecommended(node)}                                
                                select                                
                            >
                                {products.map((prod) => {                                    
                                    return <MenuItem key={prod._id} value={prod._id}>
                                                {prod.title}
                                            </MenuItem>
                                })}
                            </TextField>                                           
                        </Grid>
                        {this.state.arrRecommended.map((recom, index)=>{
                            return(
                            <Grid container spacing={2} className={classes.imageGrid} key={index}>
                                <Toolbar className={classes.toolbar}>
                                <Grid item sm={2} className={classes.gridItem}>                            
                                    <img src={recom.rec_image} alt={recom.rec_title} className={classes.imageList}/>
                                </Grid>
                                <Grid item sm={6} className={classes.gridItem}>                            
                                    {recom.rec_title}
                                </Grid>                               
                                <Grid item sm={4} className={classes.gridItem}> 
                                    <div className={classes.deliveryRowBtn}>                                                             
                                    <Button onClick={() => {this.handleDeleteRecommend(recom.rec_product_id)}}>
                                        <Delete />
                                    </Button>
                                    </div>
                                    
                                </Grid>
                                </Toolbar>
                            </Grid>)
                        })}
                    </Grid>
                    <Grid container spacing={2} className={classes.imageGrid}>
                        <Grid item sm={12} className={classes.separatorGrid}>
                            <span className={classes.labelSpan}></span>
                        </Grid>
                        <Grid item sm={12} className={classes.gridItem}>
                            <Button variant="contained" color="primary" className={classes.buttonSave} onClick={this.handleSaveProduct}>
                                Save
                                <SaveIcon className={classes.rightIcon} />
                            </Button>
                        </Grid>
                    </Grid> 
                    <Grid container spacing={2}>
                        <Link className={classes.backToListButton} to='/admin_products'>
                            <KeyboardArrowLeft />
                            <span>Back to list</span> 
                        </Link>
                    </Grid>
                </div>
            </div>
        );
    }
}

AdminPorductNew.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (store) => {
      return {
          user: store.userState.user,
          newProduct: store.adminState.newProduct,
          dialog: store.dialogState.dialog,
          alert: store.alertState.alert,
          products: store.productsState.products,
          productImages: store.productsState.productImages,
      }
  }

  export default withRouter(connect(mapStateToProps)(withStyles(styles)(AdminPorductNew)))