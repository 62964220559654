import React, {useEffect} from 'react'
import './App.css'
import {BrowserRouter, Route, RouteComponentProps } from 'react-router-dom'
import Home from './pages/Home'
import Product from './pages/BoxEdit'
import Products from './pages/ListProducts'
import HowItWorks from './pages/HowItWorksPage'
import ShoppingCart from './pages/ShoppingCart'
import Login from './pages/LoginUser'
import AdminOrders from './admin/AdminOrders'
import AdminOrderEdit from './admin/AdminOderEdit'
import AdminProducts from './admin/AdminProducts'
import AdminProductNew from './admin/AdminProductNew'
import AdminProductEdit from './admin/AdminProductEdit'
import {connect, useDispatch} from 'react-redux'
import {editProduct, requestProducts, fetchEditProduct} from './redux/action'

interface MatchParams {
  id: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
}

const App: React.FC = () => {
  
  const dispatch = useDispatch()

  const setEditProduct =(id:string) => {
    console.log("app edit product id: ", id)    
    dispatch(fetchEditProduct(id))
    return <Product/>
  }

  // useEffect(()=>{
  //   console.log("app useEffect")
  //   dispatch(requestProducts())
  // })

  return (
    <BrowserRouter>
      <div className="App">       
        <Route exact path='/'> 
          <Home/>
        </Route>
        <Route path='/products' component={Products} />
        <Route path='/product/:id' component={({match}: MatchProps)=> { return setEditProduct(match.params.id)}}/>          
        <Route path='/howitworks' component={HowItWorks} />
        <Route path='/cart'> 
          <ShoppingCart/>
        </Route>
        <Route path='/login'> 
          <Login/>
        </Route>
        <Route path='/admin'> 
          <AdminOrders/>
        </Route>
        <Route path='/admin_orders'> 
          <AdminOrders/>
        </Route>
        <Route path='/admin_order_edit'> 
          <AdminOrderEdit/>
        </Route>
        <Route path='/admin_products'> 
          <AdminProducts/>
        </Route>
        <Route path='/admin_product_new'> 
          <AdminProductNew/>
        </Route>
        <Route path='/admin_product_edit'> 
          <AdminProductEdit/>
        </Route>        
      </div>
   </BrowserRouter>
  );
}

export default App;
