import React, { useEffect, useState, ChangeEvent } from 'react'
import { Link as MLink, AppBar, Toolbar, InputBase} from "@material-ui/core"
import SearchIcon from '@material-ui/icons/Search';
import { Theme, makeStyles } from '@material-ui/core/styles'
import {useDispatch, connect} from 'react-redux'
import {searchProducts} from '../redux/action'

const useStyles = makeStyles((theme: Theme) => {
    return { 
        appBar: {
            backgroundColor: '#fbfbfb',
            color: '#000',            
        },
        searchInput: {
            backgroundColor:'#efefef',
        },     
        search: {
            display: 'flex',
            aligenItem: 'center',            
        },
        searchIcon: {
            position: 'absolute',            
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            right: 190,
            top: 20
        },         
    }
})

const SortBar: React.FC = () => {
    const classes = useStyles();
    const [searchText, setSearchText] = useState();
    const dispatch = useDispatch()


    const handleSerchText = (e: ChangeEvent<HTMLInputElement>) => {
        console.log("search input: ", e.target.value)
        dispatch(searchProducts(e.target.value))       
    }

    useEffect(()=>{
        return function cleanup(){
            dispatch(searchProducts(''))
        }
    })

    return(
        <div className="container">
            <AppBar className={classes.appBar} position="static">
                <Toolbar className={''}>
                <div className={classes.search}>                    
                    <InputBase placeholder="חיפוש" className={classes.searchInput} inputProps={{ 'aria-label': 'search' }} onChange={handleSerchText} /> 
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>                 
                </div>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default SortBar