import React from 'react';
import { makeStyles } from '@material-ui/styles'
import { Theme } from "@material-ui/core"
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => {
    return {
        howItWorksRoot: {
            backgroundColor: '#f9f9f9',
            paddingTop: '3rem',
            paddingBottom: '6rem',
        },
        howItWorksContainerBox:{
            display: 'flex',
        },
        howItWorksBoxsTitle:{
            marginBottom: 50,
        },
        howItWorksBox: {
            width: '33%',
        },
        howItWorksContainerImageText: {
            display: 'flex',
        },
        boxTextDiv: {
            paddingTop: '40px',
            paddingRight: '15px',
            fontSize: '1.4rem',
            lineHeight: '3.6rem',
            fontWeight: 500,
        },
        boxTextDesc: {
            fontWeight: 'normal',
            fontSize: '1.2rem',
            lineHeight: '1.7rem',
            color: '#9BA0A7',
            marginTop: '15px',
            textAlign: 'right',
            paddingLeft: 15,
            paddingRight: 15,
        },
        imageMealchoose:{
            width:'97px', 
            height:'121px',
        },
        imageMealbox:{
            width:'107px', 
            height:'124px',
        },
        imageMealdone:{
            width:'129px', 
            height:'128px',
        },
        generalLInk:{
            textDecoration: 'unset',
            color: '#000',
        },
        '@media (max-width: 760px)':{            
            howItWorksContainerBox:{
                display: 'unset',
            },
            howItWorksBox: {
                width: 'unset',
                padding: '0 15px',
            },       
        }
    }
})

const HowItWorks: React.FC = () => {
    const classes = useStyles()
    return(
        <div className={classes.howItWorksRoot} id="divHowItWorks">
            <div className="container">
                <Link to="/howitworks" className={classes.generalLInk}>
                <h2 className={classes.howItWorksBoxsTitle}>איך זה עובד ?</h2>
                <div className={classes.howItWorksContainerBox}>
                    <div className={classes.howItWorksBox}>
                        <div className={classes.howItWorksContainerImageText}>                            
                            <div>
                                <img className={classes.imageMealchoose} src="images/site/mealchoose.svg"  alt=""></img>
                            </div>
                            <div className={classes.boxTextDiv}>
                                בחירת מנה
                            </div>
                        </div>
                        <div className={classes.boxTextDesc}>
                               הזמינו ארוחות מתפריט מגוון. הוסיפו להם מרקים, סלטים ומנות נלוות.  
                        </div>
                    </div>
                    <div className={classes.howItWorksBox}>
                    <div className={classes.howItWorksContainerImageText}>                            
                            <div>
                                <img className={classes.imageMealbox} src="images/site/mealbox.svg" alt=""></img>
                            </div>
                            <div className={classes.boxTextDiv}>
                                קבלת המשלוח
                            </div>
                        </div>
                        <div className={classes.boxTextDesc}>
                            אנחנו נביא לכם מוצרים ומתכונים טריים למנות נבחרות. החומרים כבר נמדדים על פי מתכון. זה מאוד נוח.  
                        </div>
                    </div>
                    <div className={classes.howItWorksBox}>
                        <div className={classes.howItWorksContainerImageText}>                            
                            <div>
                                <img className={classes.imageMealdone} src="images/site/mealdone.svg" alt=""></img>
                            </div>
                            <div className={classes.boxTextDiv}>
                            הכנת האוכל
                            </div>
                        </div>
                        <div className={classes.boxTextDesc}>
                        עקוב אחר המתכון ואחרי 15-45 דקות קרא לכולם לשולחן. אתה שף אמיתי והמשפחה מרוצה! 
                        </div>
                    </div>
                </div>
                </Link>
            </div>
        </div>
    );
}

export default HowItWorks