import {takeEvery, call, put} from 'redux-saga/effects'
import {SET_ORDER_COMPLETE, SAVE_ORDER, SHOW_ALERT, FETCH_LIST_ORDERS, SET_ORDERS_LIST, ORDER_UPDATE_STATUS} from './../types'
import config from '../../config'

export function* sWtSaveOrder(){
    yield takeEvery(SAVE_ORDER, sWSaveOrder)
    yield takeEvery(FETCH_LIST_ORDERS, sWListOrders)
    yield takeEvery(ORDER_UPDATE_STATUS, sWUpdateOrderStatus)
}

function* sWSaveOrder(action){
    const url = config.params.server_path + "/new_order"
    const payload = yield call(fetchDataPost, url, action.order)
    //console.log('save order response: ', payload)
    if(payload.success){
        yield put({type: SET_ORDER_COMPLETE, savedOrder: payload.data})
        const alertData = {text: 'הזמנה נשלחה בהצלחה', variant: 'success', show: true, event: '' };
        yield put({type: SHOW_ALERT, alert: alertData})  
    }else{
        const alertData = {text: payload.text, variant: 'error', show: true, event: '' };
        yield put({type: SHOW_ALERT, alert: alertData})       
    }   
}

function* sWListOrders(){
    const url = config.params.server_path + "/orders"
    const payload = yield call(fetchDataPost, url, null)
    //console.log('save order response: ', payload)
    if(payload.success){
        yield put({type: SET_ORDERS_LIST, orders: payload.data})
    }else{
        const alertData = {text: payload.text, variant: 'error', show: true, event: '' };
        yield put({type: SHOW_ALERT, alert: alertData})       
    }   
}

function* sWUpdateOrderStatus(action){
    const url = config.params.server_path + "/orders_update_status"
    const payload = yield call(fetchDataPost, url, action.order)    
    if(payload.success){
        const alertData = {text: payload.text, variant: 'success', show: true, event: '' };
        yield put({type: SHOW_ALERT, alert: alertData}) 
    }else{
        const alertData = {text: payload.text, variant: 'error', show: true, event: '' };
        yield put({type: SHOW_ALERT, alert: alertData})       
    }   
}


async function fetchDataPost(url, data){
    console.log('fetch data input params: ', url, ' data: ',  data)
    let reqObj
    if(data !== null){       
        reqObj = { method: 'post', body: JSON.stringify(data), headers: {'Content-Type':'application/json'} }
    }else{
        reqObj = { method: 'post' }
    }    
    const response = await fetch(url, reqObj)
    return await response.json()
}