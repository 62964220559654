import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {Link as MLink, Grid } from "@material-ui/core"
import {Facebook, WhatsApp, Instagram} from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {     
      backgroundColor: '#f9f9f9',
      minHeight: 100,
      position: 'absolute',
      left: 0,
      right: 0,     
    },
    menuUl:{
        listStyle: 'none',       
        display: 'flex',
        direction: 'rtl',        
        flexDirection: 'column',
        textAlign: 'right',
        marginBottom: 50,
    },
    menuItem: {
        width: 200,
        fontWeight: 500,
        fontSize: '14pt',
        height: '30px',
        '& span': {
            marginRight: 10,
        },
    },
    menuItemTitle: {
        fontWeight: 'bold',
    },
    linkTopBar: {
        cursor: 'pointer',
        color: '#000',
    },
    containerFooter:{
        display: 'flex',
        justifyContent: 'center',
        padding: '20px 0',
    },
    instagramIcon:{
        width: 24,
        height: 24,
    },
  }),
);

const Footer: React.FC = () => {
    const classes = useStyles()
    return(
        <footer className={classes.root}>
            <div className={classes.containerFooter + " container"}>
            <Grid container spacing={2}>                
                <Grid item xs={12} sm={4} md={4} lg={4}>
                <ul className={classes.menuUl}>
                    <li className={classes.menuItem  + ' mb-15'}>
                      <span className={classes.menuItemTitle}>על החברה </span>     
                    </li>
                    <li className={classes.menuItem}>
                        <MLink href="#divHowItWorks" >
                            איך זה עובד 
                        </MLink>   
                    </li>
                    <li className={classes.menuItem}>
                        צור קשר    
                    </li>
                </ul>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <ul className={classes.menuUl}>
                        <li className={classes.menuItem + ' mb-15'}>
                        <span className={classes.menuItemTitle}>אבטחת איכות </span>     
                        </li>
                        <li className={classes.menuItem}>
                        מקור המוצרים
                        </li>
                        <li className={classes.menuItem}>
                        איך אוספים מוצרים  
                        </li>
                        <li className={classes.menuItem}>
                            משלוח 
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                <ul className={classes.menuUl}>
                        <li className={classes.menuItem + ' mb-15'}>
                        <span className={classes.menuItemTitle}>058-7-267676</span>     
                        </li>
                        <li className={classes.menuItem + ' d-flex'}>
                            <Facebook color="primary"/>
                            <span>
                                פייסבוק
                            </span>
                        </li>
                        <li className={classes.menuItem + ' d-flex'}>
                            <img src="/images/site/instagram.png" alt="אנסטגרם" className={classes.instagramIcon}/>
                            <span>
                                אנסטגרם
                            </span>
                            
                        </li>
                        <li className={classes.menuItem + ' d-flex'}>
                            <WhatsApp style={{ color: 'green' }}/>
                            <span>
                                ווטסאפ 
                            </span>
                            
                        </li>
                    </ul>  
                </Grid>
            </Grid>                
            </div>            
        </footer>
    )
}

export default Footer