const initiaNotificationState = {
    notificationList: [],
    loadNotification: false,
    showNotificationList: false,
    activeNotification: {},    
};

export default function userProfile(state = initiaNotificationState, action){    
    switch(action.type){
        case "NOTIFICATION_SET_NOTIFICATION_LIST":        
          return {...state, notificationList: action.notifications };
        case "NOTIFICATION_LOAD_NOTIFICATION":        
          return {...state, loadNotification: action.loadNotification };
        case "NOTIFICATION_SHOW_NOTIFICATION_LIST":        
          return {...state, showNotificationList: action.showNotificationList };
        case "NOTIFICATION_SET_ACTIVE_NOTIFICATION":        
          return {...state, activeNotification: action.activeNotification };
        default:
          return state;
    }
}