import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { showDialog, showAlert, requestOrders, setOrderEdit } from '../redux/action';
//import { getDataFromServer } from './../../DataProvider';
//import config from './../../config';
import store from '../store';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
//import Dialog from './../Dialog';
import SnackbarsCustom from '../components/SnackbarsCustom';
//import adminData from './AdminData';
//import SelectedListGeneral from '../components/SelectedListGeneral';
import NavBarAdmin from '../header/NavBarAdmin'
import Toolbar from '@material-ui/core/Toolbar'
import {ADMIN} from './adminTypes'
import { withRouter } from 'react-router-dom'

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding: '20px 80px 80px 80px',      
    },
    tabs: {
        backgroundColor: theme.palette.common.white,
     },
     tabTitle: {
        color: '#000',
     },
     paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      paperLink: {
        display: 'block',
        width: '100%',
        cursor: 'pointer',
      },
      rowGrid: {
        height: '40px',
        borderBottom: 'solid 1px #ccc',
        padding: '10px',
        '&:hover':{
            backgroundColor: '#ccc',
        }
      },
      button: {
        
      },
      selectColumn: {
        marginTop: '-20px',
      },
      rowsContainer: {
        marginTop: 100,
        direction: 'ltr',
      },
      toolbar: {
        width: '100%',
        borderBottom: 'solid 1px #ccc',
        minHeight: '40px',
        '&:hover':{
            backgroundColor: '#f5f2f2',
        }
      },
      rowTitle: {
        textAlign: 'left'
    },
  });

  class AdminOrders extends Component{
    constructor(props){
        super(props);
        this.state = {removeOrderId: ''};
    }  
    
    componentDidMount(){       
        if(this.props.user === null || this.props.user.permission !== ADMIN){
            this.props.history.push('/login') 
        }; 
        console.log('admin orders user: ', this.props)
        store.dispatch(requestOrders());
    }   

    removeOrderDialog = (orderId) => {
        let dialogData = { 
            title: 'Warning', 
            text: 'Do you want to remove order ?', 
            buttons: [{event: this.removeOrder, label: 'Remove order'}], 
            show: true 
        }
        //this.state.removeOrderId = orderId;
        this.setState({removeOrderId: orderId})
        store.dispatch(showDialog(dialogData));
    }

    removeOrder = () => {
        //let alert={};
        this.handlCloseDialog();        
    }    

    handlCloseDialog = (show) => {
        let dialogData = { title: '', text: '', buttons: [], show: false }
        store.dispatch(showDialog(dialogData));
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(showAlert(alert));
    }

    handleEditOrder = (orderId) => {
        store.dispatch(setOrderEdit(orderId));
        this.props.history.push('/admin_order_edit')
    }

    render(){
        let {classes, orders, alert} = this.props;
        return(
            <div className={classes.root}>
                <NavBarAdmin />
               {alert.show ? 
                    <SnackbarsCustom data={alert} />
                    :''
                }              
               <Grid container className={classes.rowsContainer}>
                    {orders.map((order, index)=> 
                        <Grid key={index} container >
                            <Toolbar className={classes.toolbar}>                        
                                <Grid item xs={6} sm={3} md={2} className={classes.rowTitle}>
                                    {order.shipping_address.receiver_name}
                                </Grid>
                                <Grid item xs={6} sm={4} md={2} className={classes.rowTitle}>
                                    {order.shipping_address.telephone}
                                </Grid>
                                <Grid item xs={6} sm={4} md={5} className={classes.rowTitle}>
                                    {order.shipping_address.address}
                                </Grid>
                                <Grid item xs={6} sm={1} md={1} className={classes.rowTitle}>
                                    {order.status}
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} >
                                    <Button className={classes.button} onClick={(node) => { this.handleEditOrder(order._id); } }>Edit</Button>
                                </Grid>
                                <Grid item xs={3} sm={2} md={1} >
                                    <Button className={classes.button} onClick={(node) => { this.removeOrderDialog(order._id); } }>Delete</Button>
                                </Grid>
                            </Toolbar>                           
                        </Grid>
                    )}
                </Grid>
            </div>
        );
    }
  }

  AdminOrders.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (store) => {
      console.log('orders state: ', store)
      return {
          user: store.userState.user,
          orders: store.ordersState.orders,
          dialog: store.dialogState.dialog,
          alert: store.alertState.alert,
      }
  }

  export default withRouter(connect(mapStateToProps)(withStyles(styles)(AdminOrders)));