import React, {useEffect, useState} from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import {Link as MLink} from '@material-ui/core'
import { withRouter, RouteComponentProps} from 'react-router-dom'
import { setAdminCurrentTab } from '../redux/action'
import { connect, useDispatch } from 'react-redux'
import {IUser} from '../interfaces/User.interface'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
        backgroundColor: '#fbfbfb',
        color: '#000',
    },
    toolbar: {
      direction: 'ltr',
    },
    menuItemDiv:{
      padding: '20px 40px',
      '& a':{
        color: '#000',
        textDecoration: 'none',
      },
    },
    menuItemActiv:{
      borderBottom: "solid 2px red",
    }
  }),
);

export interface INavBarAdminProps{
  user: IUser,
  currentTab: string,
}

type Props = RouteComponentProps & INavBarAdminProps

 const NavBarAdmin: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [active, setActive] = useState(props.currentTab)
  const dispatch = useDispatch()

  const handleButton = (path: string)=> {
    console.log('current tab 111: ', path, active)
    dispatch(setAdminCurrentTab(path))
    setActive(path)
    props.history.push('/' + path)
  }
 
  const setActiveHandler = (tab: string) => {    
    console.log('current tab: ', tab, active)
    if(tab === active){
      return classes.menuItemDiv + ' ' + classes.menuItemActiv
    }else{
      return classes.menuItemDiv
    }
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
        <div className={setActiveHandler('admin_orders')}>
          <MLink href="#" onClick={()=>{handleButton('admin_orders')}}>
            Orders
          </MLink>
        </div>
        <div className={setActiveHandler('admin_products')}>
          <MLink href="#" onClick={()=>{handleButton('admin_products')}}>
            Products
          </MLink>            
        </div>
        </Toolbar>        
      </AppBar>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  console.log('state nav bar: ', state)
  return {
    user: state.userState.user,      
    currentTab: state.adminState.currentTab
  }}

export default withRouter(connect(mapStateToProps)(NavBarAdmin))
