import React, { useState, ChangeEvent } from 'react';
import {connect, useDispatch} from 'react-redux'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles'
import { Grid, Button, TextField, Toolbar, MenuItem } from '@material-ui/core'
import {KeyboardArrowLeft, Print} from '@material-ui/icons'
import { IShoppingCart, IOrderItem} from '../interfaces/ShoppingCart.interface'
import { IAlert} from '../interfaces/Alert.interface'
import { IUser} from '../interfaces/User.interface'
import SnackbarsCustom from '../components/SnackbarsCustom'
import {ADMIN} from './adminTypes'
import {ShippingStatusList} from '../helpers/staticData'
import {orderUpdateStatus, requestOrders, showAlert} from '../redux/action'

const useStyles = makeStyles((theme: Theme) =>{
    return{
        root: {
            flexGrow: 1,
            padding: '60px 80px 80px 80px',
            maxWidth: '70%',
            marginLeft: 'auto',
            marginRight: 'auto',
            direction: 'ltr',
        },
        backToListButton:{
            fontWeight: 'bold',
            textDecoration: 'underline',
            display: 'flex',
        },
        separatorGrid:{
            marginTop: 25,
            marginBottom: 10,
            textAlign: 'center',
            borderBottom: 'solid 1px #ccc',
            fontWeight: 'bold',
        },
        labelSpan:{ 
            marginTop: '13px',
            fontSize: '17px',
            width: '150px',
        },
        ulOrderTitle: {
            listStyleType: 'none',
            width: '500px',
            margin: 'auto',
            textAlign: 'left',
        },
        liOrderTitle:{
            textAlign: 'center',
            marginBottom: '20px',
        },
        gridItem: {

        },
        margin: {
           margin: 8 
        },
        textfield: {
            width: '100%',
            fontSize: '15px',
        },
        marginTopGrid:{
            marginTop: 20,
        },
        toolbar:{
            border: 'solid 1px #ccc',
            width: '100%',
            direction: 'rtl',
            textAlign: 'right',
            minHeight: '35px',
            marginBottom: 10,
            backgroundColor: '#f0efef',
        },
        mb10:{
            marginBottom: 10,
        },
        mb30:{
            marginBottom: 30,
        },
        borderRight:{
            borderRight: 'solid 1px #ccc',
            paddingRight: '10px',
        },
        printHide:{
            
        },
        payTransaction:{
            
        },
        printDiv:{
            textAlign: 'right',
        },
        '@media print':{
            printHide:{
                display: 'none',
            },
            payTransaction:{
                display: 'none',
            }
        },
    }
})

const formateData = (_datePurchase: Date)=>{
    let d = new Date(_datePurchase)
    return d.getDate() + '/'+ d.getMonth() + '/' + d.getFullYear() + ' ' + d.getHours() + ':' + d.getMinutes() 
}   

interface IOrderEditProps{
    editOrder: IShoppingCart,
    alert: IAlert,
    user: IUser, 
}

type Props = IOrderEditProps & RouteComponentProps

const AdminOrderEdit: React.FC<Props> = (props) => {
    
    if(props.user === null || props.user.permission !== ADMIN){
        props.history.push('/login') 
    }
    
    const classes = useStyles()
    const dispatch = useDispatch()   
    const {alert, editOrder} = props
    const orderItems:IOrderItem[] = editOrder.order_items
    const [status, setStatus] = useState(editOrder.status)
    const datePurchase = formateData(editOrder.date_purchase)
    console.log('edit product: ', editOrder)
    
    if(editOrder === null){
        props.history.push('/admin_orders')       
    }    
    
    const onChangeStatus = (e: ChangeEvent<HTMLInputElement>) =>{
        setStatus(e.target.value)
        editOrder.status = e.target.value
        dispatch(orderUpdateStatus(editOrder))
        dispatch(requestOrders())
    }
    
    const printOrder = () => {
        window.print()
    }
    
    const onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        dispatch(showAlert(alert));
    }
    
    if(alert.show){
        setTimeout(onAlertClose, 1500)
    }

    return(
        <>
         {alert.show ? 
            <SnackbarsCustom data={alert} />
            :''
         }
         {editOrder !== null ?       
            <div className={classes.root}>
                <Grid container spacing={8} className={classes.printHide}>
                    <Link className={classes.backToListButton} to='/admin_orders'>
                        <KeyboardArrowLeft />
                        <span>Back to list</span> 
                    </Link>
                </Grid>
                <Grid container spacing={2} className={classes.printHide}>
                    <Grid item sm={12} className={classes.separatorGrid}>
                        <span className={classes.labelSpan}>Order</span>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.printHide}>
                    <Grid item sm={12} className={classes.printDiv}>
                        <Button variant="contained" color="primary" onClick={printOrder}>
                            <Print />
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>                    
                    <Grid item sm={4} xs={4} className={classes.gridItem}>                            
                        <TextField
                            className={classes.margin +' '+ classes.textfield}                                
                            label="Order number"                               
                            value={editOrder.sku}
                            disabled
                        />           
                    </Grid>
                    <Grid item sm={4} xs={4} className={classes.gridItem}>                            
                        <TextField
                            className={classes.margin +' '+ classes.textfield}                                
                            label="Order date"                               
                            value={datePurchase}
                            disabled
                        />           
                    </Grid>
                    <Grid item sm={4} xs={4} className={classes.gridItem}>                            
                        <TextField
                            className={classes.margin +' '+ classes.textfield}                                
                            label="Order status"                               
                            value={status}                            
                            select
                            onChange={onChangeStatus}
                        >
                            {
                                ShippingStatusList.map((s, i)=>{
                                  return <MenuItem key={i} value={s}>{s}</MenuItem>
                                })
                            }
                        </TextField>           
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.marginTopGrid}>                   
                    <Grid item sm={2} xs={3} className={classes.gridItem}>                            
                        <TextField
                            className={classes.margin +' '+ classes.textfield}                                
                            label="Price"                               
                            value={editOrder.price}
                            disabled
                        />           
                    </Grid>
                    <Grid item sm={2} xs={3} className={classes.gridItem}>                            
                        <TextField
                            className={classes.margin +' '+ classes.textfield}                                
                            label="Vat"                               
                            value={editOrder.vat}
                            disabled
                        />           
                    </Grid>
                    <Grid item sm={2} xs={3} className={classes.gridItem}>                            
                        <TextField
                            className={classes.margin +' '+ classes.textfield}                                
                            label="Shipping"                               
                            value={editOrder.shipping_cost}
                            disabled
                        />           
                    </Grid>                  
                    <Grid item sm={2} xs={3} className={classes.gridItem}>                            
                        <TextField
                            className={classes.margin +' '+ classes.textfield}                                
                            label="Pay status"                               
                            value={editOrder.pay_status}
                            disabled
                        />           
                    </Grid>
                    <Grid item sm={4} className={classes.gridItem +' '+ classes.payTransaction}>
                        <TextField
                            className={classes.margin +' '+ classes.textfield}                                
                            label="Pay transaction"                               
                            value={editOrder.pay_transaction_id}
                            disabled
                        />           
                    </Grid>                                                          
                </Grid>
                <Grid container spacing={2} className={classes.separatorGrid}>                    
                    <Grid item sm={2} xs={4} className={classes.gridItem}>                            
                        <TextField
                            className={classes.margin +' '+ classes.textfield}                                
                            label="Name"                               
                            value={editOrder.shipping_address.receiver_name}
                            disabled
                        />           
                    </Grid>                                       
                    <Grid item sm={2} xs={4} className={classes.gridItem}>                            
                        <TextField
                            className={classes.margin +' '+ classes.textfield}                                
                            label="Telephone"                               
                            value={editOrder.shipping_address.telephone}
                            disabled
                        />           
                    </Grid>   
                    <Grid item sm={2} xs={4} className={classes.gridItem}>                            
                        <TextField
                            className={classes.margin +' '+ classes.textfield}                                
                            label="Email"                               
                            value={editOrder.email}
                            disabled
                        />           
                    </Grid>
                    <Grid item sm={6} xs={12} className={classes.gridItem}>                            
                        <TextField
                            className={classes.margin +' '+ classes.textfield}                                
                            label="Address"                               
                            value={editOrder.shipping_address.address}
                            disabled
                        />           
                    </Grid> 
                    <Grid item sm={12} xs={12} className={classes.gridItem}>                            
                            <TextField
                                className={classes.margin+' '+classes.textfield}
                                label="Description"                                                               
                                multiline
                                rowsMax="6"
                                margin="normal"              
                                variant="outlined"
                                value={editOrder.description}
                            />           
                    </Grid>                       
                </Grid>
                <Grid container spacing={2}>
                    <Grid item sm={12} className={classes.separatorGrid +' '+ classes.mb30}>
                        <span className={classes.labelSpan}>Order Items</span>
                    </Grid>
                </Grid>
                {orderItems.map((item)=>{
                    return(
                        <Grid container>
                            <Toolbar className={classes.toolbar}>
                                <Grid item sm={1} className={classes.gridItem}>
                                    {item.item_sku}
                                </Grid>
                                <Grid item sm={10} className={classes.gridItem +' '+ classes.borderRight}>
                                    {item.title}
                                </Grid>
                                <Grid item sm={1} className={classes.gridItem +' '+ classes.borderRight}>
                                    {item.quantity}
                                </Grid>
                            </Toolbar>
                        </Grid>
                    )
                })}
            </div>
            :
            <div>Can't find order</div>
        }
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        editOrder: state.ordersState.editOrder,     
        alert: state.alertState.alert,
        user: state.userState.user,
    }   
   }

export default withRouter(connect(mapStateToProps)(AdminOrderEdit)) 