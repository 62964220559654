import React, {useState} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import {Typography, Link as LinkMaterial, AppBar, Toolbar} from '@material-ui/core'
import {connect, useDispatch} from 'react-redux'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import {IShoppingCart, IOrderItem} from '../interfaces/ShoppingCart.interface'
import { IProduct } from '../interfaces/Product.interface'
import {setBuyNowProduct, setShoppingCart, setBuyNow} from '../redux/action'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
        backgroundColor: '#fbfbfb',
        color: '#000',
    },
    button:{
      color: '#000',
      fontSize: '12pt',
      fontWeight: 600,
      lineHeight: '1rem',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      marginRight: 'auto',                
      '&:hover': {
        textDecoration: 'underline',
      }
    },
    boxBtnOrder:{     
      width: '140px',            
      border: 'solid 1px #2F519C',
      borderRadius: '25px',
      fontSize: '12pt',
      fontWeight: 600,
      marginLeft: 20,
      padding: '10px 0',
      display: 'inline-block',
      lineHeight: '1rem',
      textDecoration: 'none',               
    },
    buttonBuy:{
      backgroundColor: '#2F519C',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#3b65c3',
        textDecoration: 'none',
      }
    },
    buttonCart:{      
      color: '#2F519C',
      '&:hover': {        
        
      }
    },
    price: {      
      fontSize: '2rem',
      fontWeight: 700,
      color: '#2F519C',
      marginRight: '30px',
    },
    boxSubTitle:{
      display: 'flex',
      justifyContent: 'center'
    },
    boxSubTitleItem: {
        display: 'flex',
        marginLeft: '30px',
    },
    textFieldCount: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 70, 
      height: '17px',
      borderTop: 'unset',
      borderRight: 'unset',
      borderLeft: 'unset',
      borderBottom: 'solid 1px #000',
      fontSize: '17px',
      textAlign: 'center',
      outline:0,          
    },
    countContainer: {
      display: 'flex',
      width: '140px',
      height: '20px',
      justifyContent: 'center',
      padding: '10px 10px',
      marginRight: '20px',
      textDecoration: 'none',
      '&:hover':{
        textDecoration: 'none !important',
      }
    },
    logoContainer:{
      position: 'absolute',
    },
    logoImg: {
      width: '170px',
      marginRight: 30,
    },
    divBtnContainer: {
      marginRight: 'auto',
    },
    '@media (max-width: 420px)':{
      root:{
        display: 'none',
      }
    }   
  }),
);

interface IBoxEditProps{
  product: IProduct
  buyNowObj: IShoppingCart
  shoppingCart: IShoppingCart
}

type Props = IBoxEditProps & RouteComponentProps

 const NavBarEdit: React.FC<Props> = (props) => {
  const classes = useStyles();
  const product: IProduct = props.product
  const [count, setCount] = useState('1')
  const dispatch = useDispatch()

  const addToBuyNowCart = () => {
    let buyNowObj: IShoppingCart = props.buyNowObj       
    
    const orderItem: IOrderItem = {
        item_sku: product.sku,
        title: product.title,
        description: product.description,
        quantity: count,
        item_price: product.price,
        item_price_total: product.price * parseInt(count),            
        image: {link: product.images[0].link, title: product.images[0].title}
    }

    buyNowObj.order_items = []
    buyNowObj.order_items.push(orderItem)    
    dispatch(setBuyNow(true))
    dispatch(setBuyNowProduct(buyNowObj))
    props.history.push('/cart')
    
}

const addToShoppingCart = () => {
  let shoppingCart: IShoppingCart = props.shoppingCart     

  const indexItem = shoppingCart.order_items.findIndex(i=>i.item_sku === product.sku)
  console.log('nav bar edit index: ', indexItem)
  if(indexItem !== -1){
    let count:string = shoppingCart.order_items[indexItem].quantity
    console.log('nav bar edit index: ', count)
    shoppingCart.order_items[indexItem].quantity = (parseInt(count) + 1).toString()
    shoppingCart.order_items[indexItem].item_price_total = shoppingCart.order_items[indexItem].item_price_total + product.price
    console.log('nav bar edit index: ', shoppingCart)
  }else{
    const orderItem: IOrderItem = {
      item_sku: product.sku,
      title: product.title,
      description: product.description,
      quantity: count,
      item_price: product.price,
      item_price_total: product.price * parseInt(count),
      image: {link: product.images[0].link, title: product.images[0].title}
    }
    shoppingCart.order_items.push(orderItem)
  }
  dispatch(setBuyNow(false))
  dispatch(setShoppingCart(shoppingCart))
  //props.history.push('/cart')
}

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <div className={classes.logoContainer}>
          <img src="/images/site/logo.png" alt="" className={classes.logoImg}/>                    
        </div> 
        <Toolbar className="container">        
           <Typography component="div" className={classes.divBtnContainer}>
              <LinkMaterial className={classes.boxBtnOrder +' '+ classes.buttonBuy} onClick={addToBuyNowCart} >לקנות</LinkMaterial>
              <LinkMaterial href="#" className={classes.boxBtnOrder +' '+ classes.buttonCart} onClick={addToShoppingCart}>להוסיף לסל</LinkMaterial>
           </Typography>
           <Typography component="div" className={classes.price}>
              <span>&#8362;</span>
              <span>{parseInt(count) > 0 ? product.price * parseInt(count): product.price}</span>              
            </Typography>            
            <LinkMaterial href="#" className={classes.boxBtnOrder +' '+ classes.buttonCart + ' ' + classes.countContainer} >
              <span>
              כמות:
              </span>
            <input type="number" min='1' max='100' value={count} className={classes.textFieldCount} onChange={(e)=>{setCount(e.target.value)}}/>
            </LinkMaterial>
           <Link to='/' className={classes.button}>
             עמוד הבית
             <KeyboardArrowLeft/>
           </Link>
        </Toolbar>
      </AppBar>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return{
      product: state.productsState.editProduct,
      buyNowObj: state.productsState.buyNowObj,
      shoppingCart: state.shoppingCartState.shoppingCart,
  }
}

export default withRouter(connect(mapStateToProps)(NavBarEdit)) 
