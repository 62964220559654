//PRODUCTS
export const SET_LIST_PRODUCTS = 'SET_LIST_PRODUCTS'
export const GET_LIST_PRODUCTS = 'GET_LIST_PRODUCTS'
export const SET_EDIT_PRODUCT = 'SET_EDIT_PRODUCT'
export const GET_EDIT_PRODUCT = 'GET_EDIT_PRODUCT'
export const REQUEST_LIST_PRODUCTS = 'REQUEST_LIST_PRODUCTS'
export const SORT_PRODUCT_PRICE = 'SORT_PRODUCT_PRICE'
export const NEW_PRODUCT = 'NEW_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const SET_BUY_NOW = 'SET_BUY_NOW'
export const SET_BUY_NOW_OBJECT = 'SET_BUY_NOW_OBJECT'
export const SET_SERVER_EDIT_PRODUCT = 'SET_SERVER_EDIT_PRODUCT'
export const FETCH_EDIT_PRODUCT = 'FETCH_EDIT_PRODUCT'
export const FETCH_PRODUCTS_IMAGES = 'FETCH_PRODUCTS_IMAGES'
export const SET_PRODUCTS_IMAGES = 'SET_PRODUCTS_IMAGES'
export const SEARCH_PRODUCTS_TEXT = 'SEARCH_PRODUCTS_TEXT'

//SHOPPING CART
export const SET_SHOPPING_CART = 'SET_SHOPPING_CART'

//ORDERS
export const SAVE_ORDER = 'SAVE_ORDER'
export const FETCH_LIST_ORDERS = 'FETCH_LIST_ORDERS'
export const SET_ORDER_COMPLETE = 'SET_ORDER_COMPLETE'
export const SET_ORDERS_LIST = 'SET_ORDERS_LIST'
export const SET_EDIT_ORDER = 'SET_EDIT_ORDER'
export const ORDER_UPDATE_STATUS = 'ORDER_UPDATE_STATUS'

//USER
export const LOGIN_USER = 'LOGIN_USER'
export const SET_LOGIN_USER = 'SET_LOGIN_USER'

//GENERAL
export const SHOW_ALERT = 'SHOW_ALERT'

//ADMIN
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB'
