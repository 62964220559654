import {takeEvery, put, call} from 'redux-saga/effects'
import {REQUEST_LIST_PRODUCTS, 
    SHOW_ALERT, 
    SET_LIST_PRODUCTS, 
    NEW_PRODUCT, 
    UPDATE_PRODUCT, 
    DELETE_PRODUCT, 
    FETCH_EDIT_PRODUCT, 
    SET_SERVER_EDIT_PRODUCT,
    FETCH_PRODUCTS_IMAGES,
    SET_PRODUCTS_IMAGES,
} from './../types'
import config from '../../config'

export function* sWtProduct(){
    yield takeEvery(REQUEST_LIST_PRODUCTS, sWProductList)
    yield takeEvery(NEW_PRODUCT, sWProductAdd)
    yield takeEvery(UPDATE_PRODUCT,sWProductUpdate)
    yield takeEvery(DELETE_PRODUCT, sWProductDelete)
    yield takeEvery(FETCH_EDIT_PRODUCT, sWProductEdit)
    yield takeEvery(FETCH_PRODUCTS_IMAGES, sWProductsImages)
}

function* sWProductList(){
    //yield put() //dispatch to store 
    console.log(`sWProductList saga`)  
    const url = config.params.server_path + '/products'
    const payload = yield call(fetchDataPost, url, null)
    if(payload.success){
        yield put({type: SET_LIST_PRODUCTS, products: payload.data})
    }else{
        const alertData = {text: payload.text, variant: 'error', show: true, event: '' };
        yield put({type: SHOW_ALERT, alert: alertData})
    }
}

function* sWProductAdd(action){
    //yield put() //dispatch to store 
    console.log(`sWProductAdd saga`)  
    const url = config.params.server_path + '/new_product'
    const payload = yield call(fetchDataPost, url, action.product)
    let alertData = {}
    if(payload.success){
        alertData = {text: payload.text, variant: 'success', show: true, event: '' };
    }else{
        alertData = {text: payload.text, variant: 'error', show: true, event: '' };
    }
    yield put({type: SHOW_ALERT, alert: alertData})
    console.log("saved product obj: ", payload.data, ", id: ", payload.data._id)
    //yield put({type: SET_EDIT_PRODUCT, productId: payload.data._id})
}

function* sWProductUpdate(action){
    //yield put() //dispatch to store 
    console.log(`sWProductAdd saga`)  
    const url = config.params.server_path + '/update_product'
    const payload = yield call(fetchDataPost, url, action.product)
    let alertData = {}
    if(payload.success){
         alertData = {text: payload.text, variant: 'success', show: true, event: '' };        
    }else{
         alertData = {text: payload.text, variant: 'error', show: true, event: '' };        
    }
    yield put({type: SHOW_ALERT, alert: alertData})  
}

function* sWProductDelete(action){
    //yield put() //dispatch to store 
    console.log(`sWProductDelete saga`)  
    const url = config.params.server_path + '/remove_product'
    const payload = yield call(fetchDataPost, url, {productId: action.productId})
    let alertData = {}
    if(payload.success){
         alertData = {text: payload.text, variant: 'success', show: true, event: '' };
         yield sWProductList()      
    }else{
         alertData = {text: payload.text, variant: 'error', show: true, event: '' };        
    }
    yield put({type: SHOW_ALERT, alert: alertData})
}

function* sWProductEdit(action){    
    const url = config.params.server_path + '/get_product_by_id'
    const payload = yield call(fetchDataPost, url, {productId: action.productId})   
    if(payload.success){
        console.log('sWProductEdit res: ', payload.data)
        yield put({type: SET_SERVER_EDIT_PRODUCT, product: payload.data}) 
    }else{
        console.log(`sWProductEdit can't get product by id `)  
    }   
}

function* sWProductsImages(){    
    const url = config.params.server_path + '/get_files'
    const payload = yield call(fetchDataPost, url, null)
    if(payload.success){
        console.log('sWProductsImages res: ', payload.data)
        yield put({type: SET_PRODUCTS_IMAGES, images: payload.data})
    }else{
        console.log(`sWProductsImages can't get products images`)  
    }   
}

async function fetchDataPost(url, data){
    console.log('fetch data input params: ', url, ' data: ',  data)
    let reqObj
    if(data !== null){       
        reqObj = { method: 'post', body: JSON.stringify(data), headers: {'Content-Type':'application/json'} }
    }else{
        reqObj = { method: 'post' }
    }    
    const response = await fetch(url, reqObj)
    return await response.json()
}