import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {KeyboardArrowLeft, Menu as MenuIcon} from '@material-ui/icons'
import {AppBar, Toolbar, Link as MLink, IconButton} from '@material-ui/core'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import SideMenuMobile from '../components/SideMenuMobile'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
        backgroundColor: '#fbfbfb',
        color: '#000',
    },
    button:{
      color: '#000',
      fontSize: '12pt',
      fontWeight: 600,
      lineHeight: '1rem',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      marginRight: 'auto',                
      '&:hover': {
        textDecoration: 'underline',
      }
    },
    logoContainer:{
      position: 'absolute',
      zIndex: 1,
    },
    logoImg: {
      width: '170px',
      marginRight: 30,
    },
    menuUl:{
      listStyle: 'none',
      margin: 0,
      padding: 0,
      display: 'flex',
      direction: 'rtl',
  },
  menuItem: {
      width: 200,
      fontWeight: 500,
      fontSize: '17pt',
  },
  linkTopBar: {
    cursor: 'pointer',
    color: '#000',
    textDecoration: 'unset',
    '&:hover':{
      textDecoration: 'underline',
    }
  },
  divGeneralButtons:{
    position: 'fixed',
    left: 0,
    right: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  hide: {
    display: 'none',
  },
  mobileMenuIcon: {
      width: 40,
      height: 40,
      color: '#2F519C',
  },
  mobileMenuIconContainer:{
      position: 'absolute',
      top: 10,
      left: 10,
      display: 'none',
  },
  divHomeButton:{
    marginRight: 'auto',
  },
  '@media (max-width: 420px)':{
        button:{
          marginLeft: '40px'
        },
        divGeneralButtons:{
          display: 'none'
        },
        logoImg:{
          width: '140px',
          marginRight: 10,
          marginTop: '3px',
        },
        mobileMenuIconContainer: {
          display: 'block',
          top: 0,
          left: 35,
        },
        divHomeButton:{
          display: 'none',
        },
    },
  }),
);

export interface INavBarCart{
  showGeneralBtn: boolean,
  showHomeBtn: boolean,
}

type Props = INavBarCart

 const NavBarCart: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [showGeneralButtons, setShowGeneralButtons] = useState(props.showGeneralBtn)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const generalButtons = (
    <div className={classes.divGeneralButtons}>
      <ul className={classes.menuUl}>
          <li className={classes.menuItem}>
              <Link to="/howitworks" className={classes.linkTopBar}>
                  איך זה עובד 
              </Link>                       
          </li>
          <li className={classes.menuItem}>
              <Link to="/products" className={classes.linkTopBar}>
                  תפריט   
              </Link>
          </li>        
          <li className={classes.menuItem}>058-7-267676</li>        
      </ul>
    </div>    
  )

  const homeButton = (
    <div className={classes.divHomeButton}>
      <Link to='/' className={classes.button}>
        עמוד הבית
        <KeyboardArrowLeft/>
      </Link>
    </div>    
  )

  const handleDrawerOpen =()=>{
    setDrawerOpen(true);
  }

  const handleDrawerClose =()=>{
    setDrawerOpen(false);
  }

  const mobileButton = (
    <div className={classes.mobileMenuIconContainer}>
      <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          className={''}                    
      >
          <MenuIcon className={classes.mobileMenuIcon}/>
      </IconButton>                
</div>
  )

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <div className={classes.logoContainer}>
          <Link to='/' className={classes.button}>
            <img src="/images/site/logo.png" alt="" className={classes.logoImg}/> 
          </Link>                             
        </div> 
        <Toolbar className="container">         
          {props.showGeneralBtn? generalButtons: ''}
          {props.showHomeBtn? homeButton: ''}
          {mobileButton}
          <SideMenuMobile show={drawerOpen} onClose={handleDrawerClose} />
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default NavBarCart 
