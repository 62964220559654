const initiaMessagesState = {
    messagesList: [],
    countNotReaded: 0,
    loadMessages: false,
    activeIndustryMessage: {},   
};

export default function industryMessages(state = initiaMessagesState, action){    
    switch(action.type){
        case "MESSAGES_SET_MESSAGES_LIST":        
          return {...state, messagesList: action.messages };
        case "MESSAGES_SET_COUNT_NOT_READED":        
          return {...state, countNotReaded: action.countNotReaded };
        case "MESSAGES_SET_LOAD_MESSAGES":        
          return {...state, loadMessages: action.loadMessages };
        case "MESSAGES_SET_ACTIVE_INDUSTRY_MESSAGE":        
          return {...state, activeIndustryMessage: action.activeIndustry };     
        default:
          return state;
    }
}