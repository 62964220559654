import {NEW_PRODUCT, SET_CURRENT_TAB} from '../types'
const initialndustryState = {
      newProduct: {images:[]},
      currentTab: '',      
  };
  
  export default function userProfile(state = initialndustryState, action){
      switch(action.type){      
          case NEW_PRODUCT:
            return { ...state, newProduct: action.product};
          case SET_CURRENT_TAB:
            return { ...state, currentTab: action.currentTab};
          default:
            return state;
      }   
  }