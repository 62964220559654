import {SET_LIST_PRODUCTS, 
  SET_EDIT_PRODUCT, 
  SORT_PRODUCT_PRICE, 
  SET_BUY_NOW, 
  SET_BUY_NOW_OBJECT, 
  SET_SERVER_EDIT_PRODUCT,
  SET_PRODUCTS_IMAGES,
  SEARCH_PRODUCTS_TEXT,
} from '../types'

const getRndInteger = () => {
  return Math.floor(Math.random() * (10000000 - 1000)) + 1000;
}

const initialProductState = {
  products: [],
  filterProducts: [],
  productImages: {},  
  sortPrice: '',
  activeTab: 0, 
  searchText: '',
  editProduct: null,
  buyNow: false,  
  buyNowObj: {
    sku: getRndInteger(),
    description: '',    
    price: 0,
    vat: 17,
    is_free_shipping: true,
    shipping_address: {},
    quantity: 1,
    status: "",
    shipping_date: "",
    user_id: "",  
    pay_transaction_id: "",
    pay_status: "",
    shipping_cost: 0,
    date_purchase: Date.now(),
    email:"", 
    order_items: []
  }
};

export default function products(state = initialProductState, action){    
    switch(action.type){      
        case SET_LIST_PRODUCTS:
          return {...state, products: action.products};
        case SET_EDIT_PRODUCT:
          return {...state, editProduct: state.products.filter(p => p._id === action.productId)[0]};
        case SET_SERVER_EDIT_PRODUCT:
          return {...state, editProduct: action.product};
        case SORT_PRODUCT_PRICE:
          return {...state, sortPrice: action.sortPrice};
        case SET_BUY_NOW_OBJECT:          
          return {...state, buyNow: true, buyNowObj: action.buyNowObj };
        case SET_BUY_NOW:
          return {...state, buyNow: action.buyNow };
        case SET_PRODUCTS_IMAGES:
          return {...state, productImages: action.images};
        case SEARCH_PRODUCTS_TEXT:
          if(action.searchText !== ''){
            return {...state, filterProducts: state.products.filter(p => p.title.includes(action.searchText))};
          }else{
            return {...state, filterProducts: []};
          }            
        default:
          return state;
    }   
}