import { createStore, applyMiddleware, compose } from 'redux'
import reducers from './redux/reducers'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './redux/sagas/root.saga'

const sagaMiddleware = createSagaMiddleware()
// const store = createStore(reducers, 
//     compose(applyMiddleware(sagaMiddleware), 
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()));

const store = createStore(reducers, compose(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga)

export default store;