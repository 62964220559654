import {all} from 'redux-saga/effects'
import {sWtProduct} from './product.saga'
import {sWtLoginUser} from './user.saga'
import {sWtSaveOrder} from './order.saga'

export default function* rootSaga(){
    yield all([
        sWtProduct(),
        sWtLoginUser(),
        sWtSaveOrder()
    ])
}